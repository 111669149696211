import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'antd';
import { Outlet, useNavigate, useLocation, Navigate, Link } from "react-router-dom";
import routes from "./routes";

import AccessControlRepository from '../repository/access-control';


async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}
const PrivateRoute = () => {

    const [newRoutes, setNewRoutes] = useState([]);
    const [session, setSession] = useState({});
    const [userRole, setUserRole] = useState('');
    let loginStatus = useSelector((state) => state.auth.isLogged)
    let { userDetails, isLogged } = useSelector((state) => state.auth);
    let location = useLocation();




    useEffect(() => {

        (async () => {
            let local = localStorage.getItem('session')
            let session = JSON.parse(local)
            setSession(session)
            let ctr = {}
            console.log(session);
            ctr.id = session?.role
            const groupData = await AccessControlRepository.getGroup(ctr);
            if (groupData.status_code == 200) {
                let finded = groupData.data.data
                let newArray = routes//.filter(r => r.showInMenu)
                await asyncForEach(newArray, async (one_Doc) => {
                    if (one_Doc.name == 'Dashboard') {
                        await asyncForEach(finded, async (oneDoc) => {
                            if (oneDoc.features.dashboard !== null) {
                                one_Doc.auth = oneDoc.features.dashboard == true ? [`${oneDoc.title}`] : []
                            }
                        })
                    }
                    if (one_Doc.name == 'Tickets') {
                        await asyncForEach(finded, async (oneDoc) => {
                            if (oneDoc.features.ticket_screens !== null) {
                                one_Doc.auth = oneDoc.features.ticket_screens ? [`${oneDoc.title}`] : []
                            }
                        })
                    }
                    if (one_Doc.name == 'Settings') {
                        await asyncForEach(finded, async (oneDoc) => {
                            if (oneDoc.features.settings !== null) {
                                one_Doc.auth = oneDoc.features.settings ? [`${oneDoc.title}`] : []
                            }
                        })
                    }
                    if (one_Doc.name == 'Jobs') {
                        await asyncForEach(finded, async (oneDoc) => {
                            if (oneDoc.features.jobs !== null) {
                                one_Doc.auth = oneDoc.features.jobs ? [`${oneDoc.title}`] : []
                            }
                        })
                    }
                    if (one_Doc.name == 'Job') {
                        await asyncForEach(finded, async (oneDoc) => {
                            if (oneDoc.features.jobs !== null) {
                                one_Doc.auth = oneDoc.features.jobs ? [`${oneDoc.title}`] : []
                            }
                        })
                    }
                    if (one_Doc.name == 'Clients') {
                        await asyncForEach(finded, async (oneDoc) => {
                            if (oneDoc.features.client !== null) {
                                one_Doc.auth = oneDoc.features.client ? [`${oneDoc.title}`] : []
                            }
                        })
                    }
                    if (one_Doc.name == 'Periodic') {
                        await asyncForEach(finded, async (oneDoc) => {
                            if (oneDoc.features.seriodical_reviews !== null) {
                                one_Doc.auth = oneDoc.features.seriodical_reviews ? [`${oneDoc.title}`] : []
                            }
                        })
                    }
                    if (one_Doc.name == 'Access Control') {
                        await asyncForEach(finded, async (oneDoc) => {
                            if (oneDoc.features.access_ccontrol !== null) {
                                one_Doc.auth = oneDoc.features.access_ccontrol ? [`${oneDoc.title}`] : []
                            }
                        })
                    }
                })
                setUserRole(groupData.data.data[0].title)
                setNewRoutes(newArray)
            }

        }
        )()
    }, []);
    let path = location.pathname.split("/")[2];
    let access = newRoutes
        ?.find((r) => r.path.split("/")[0] === path)
        ?.auth?.find((r) => r === userRole)
        ? true
        : false;

console.log(access,"sdgadgfv")

    return isLogged ? (
        access ? (
            <Outlet />
        ) : (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <Spin size="large"/>
                {/* <div className="text-center">
                    <h1 className="display-1 fw-bold">404</h1>
                    <p className="fs-3"> <span className="text-danger">Opps!</span> Page not found.</p>
                    <p className="lead">
                        The page you’re looking for doesn’t exist.
                    </p>
                    <Link to="/" className="btn btn-primary">Go Home</Link>
                </div> */}
            </div>
        )
    ) :
        (<Navigate to="/login" state={{ from: location }} replace />)

}

export default PrivateRoute;
