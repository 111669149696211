import Repository, { apiUrl } from './index';

class LoginRepository {
    constructor(callback) {
        this.callback = callback;
    }

    async login(payload) {
        let url = `${apiUrl}/auth/login`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
    async signup(payload) {
        let url = `${apiUrl}/auth/register`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
    async analyst() {
        let url = `${apiUrl}/auth/getanalyst`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
    async groups() {
        let url = `${apiUrl}/auth/getgroups`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
    async getprofile() {
        let url = `${apiUrl}/auth/getprofile`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
    async updateProfile(payload) {
        let url = `${apiUrl}/auth/profile`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }


    async updateProfileById(Id, formdata) {
        let url = `${apiUrl}/auth/update/${Id}`;
        const reponse = await Repository.put(url, formdata)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                return error.response.data;
            });
        return reponse;
    }


}

export default new LoginRepository();