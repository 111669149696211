
import React, { useEffect, useState } from 'react';

import { Select, Table, Switch, Modal, Pagination } from "antd";
import AccessControl from '../../repository/access-control';
import AuthRepository from '../../repository/authRep';

export default function GroupManagement() {
  const { Option } = Select;
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSizeTotal, setPageSizeTotal] = useState(5)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isdeleteModalOpen, setIsdeleteModalOpen] = useState(false);
  const [groupArray, setGroupArray] = useState([])
  const [showDropdown, setShowDropdown] = useState(0);
  const [tableData, setTableData] = useState([])
  const [selectedUserId, setSelectedUserId] = useState('')
  const [selectedUserName, setSelectedUserName] = useState('')
  const [count, setCount] = useState(0);
  const [selectedId, setSelectedId] = useState('');

  const [reason, setReason] = useState('');

  const [staffName, setStaffName] = useState('');
  const [designation, setDesignation] = useState('');
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [groupId, setGroupId] = useState('');
  const [staffId, setStaffId] = useState('');

  useEffect(() => {


    getGroup()
  }, []);

  const getGroup = async () => {
    let ctr = {}
    const data = await AccessControl.getGroup(ctr);
    if (data && data?.data?.data?.length) {
      setGroupArray(data.data.data)
      showactiveMenu(data.data.data[0]._id)
    }
  }

  const onChangeHandler = (setIdentifierState, event) => {
    setIdentifierState(event?.target?.value)
  }

  const showactiveMenu = async (id) => {

    let ctr = {}
    ctr.role = id
    ctr.start = currentPage === 1 ? 0 : ((currentPage - 1) * pageSizeTotal)
    ctr.limit = pageSizeTotal

    const data = await AccessControl.analyst(ctr);
    const count = await AccessControl.analystCount(ctr);
    if (count && count?.count) {
      setCount(count.count)
    }
    let objs = data?.data?.map((a, i) => {

      return {
        name: a.name,
        username: a.user_name,
        staffID: a.staffId,
        designatedGroup: a.label,
        designation: a.description,
        actions: (
          <div className='d-flex'>
            <button className='action-btn' onClick={() => editModalOnClick(a)}><i className="far fa-edit"></i></button>
            <button className='action-btn' style={{ color: "red" }} onClick={() => showdelModal(a)}><i className="far fa-trash"></i></button>

          </div>
        )

      }
    })
    setTableData(objs)
    setShowDropdown(id)

  }
  const handleOk = async () => {

    let obj = {

      staffId: staffId,
      designation: designation,
      role: groupId,
      reason: reason,
    }
    await AuthRepository.updateProfileById(selectedId, obj)
    getGroup()
    setIsModalOpen(false);

  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const editModalOnClick = (data) => {
    setEmail(data.email)
    setUserName(data.user_name)
    setStaffId(data.staffId)
    setDesignation(data.designation)
    setGroupId(data.role)
    setReason(data?.reason ? data.reason : '')
    setSelectedId(data._id)
    setIsModalOpen(true);
  }

  const showdelModal = (data) => {
    setSelectedUserName(data.user_name)
    setSelectedUserId(data._id)
    setIsdeleteModalOpen(true)
  }
  const handledelOk = () => {
    setIsdeleteModalOpen(false);
    deleteUser(selectedUserId);
  };
  const handledelCancel = () => {
    setIsdeleteModalOpen(false);
  };

  const groupOnChange = async (id) => {
    setGroupId(id)
  }

  const deleteUser = async (id) => {
    let obj = {
      isDelete: true
    }
    await AuthRepository.updateProfileById(id, obj)
    getGroup()
  }

  let columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Username",
      dataIndex: "username",
    },
    {
      title: "Staff ID",
      dataIndex: "staffID",
    },
    {
      title: "Designated Group",
      dataIndex: "designatedGroup",
    },
    {
      title: "Designation",
      dataIndex: "designation",
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
    },
  ];

  const pageSizeChange = async (page, pageSize) => {
    let ctr = {}
    ctr.start = page === 1 ? 0 : ((page - 1) * pageSize)
    ctr.limit = pageSize
    const data = await AccessControl.analyst(ctr);
    let objs = data?.data?.map((a, i) => {
      return {
        name: a.name,
        username: a.user_name,
        staffID: a.staffId,
        designatedGroup: a.label,
        designation: a.description,
        actions: (
          <div className='d-flex'>
            <button className='action-btn' onClick={() => editModalOnClick(a)}><i className="far fa-edit"></i></button>
            <button className='action-btn' style={{ color: "red" }} onClick={() => showdelModal(a)}><i className="far fa-trash"></i></button>

          </div>
        )

      }
    })
    setTableData(objs)
    setCurrentPage(page)
    setPageSizeTotal(pageSize)
  }

  return (
    <div className="row">
      <div className="col-md-2">
        <div className="border-box">
          <ul className="select-list">
            {groupArray && groupArray.map((m, index) => {

              return (
                <li onClick={() => showactiveMenu(m._id)} className={showDropdown == m._id ? "active" : ''} key={m._id}>{m.title}</li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className="col-md-10">
        <div className="border-box">
          {/* <div className="d-flex justify-content-between align-items-center mb-4">
            <h5 className="mb-0">Granted Screens</h5>
            <button
              className="primary-btn"
              onClick={showModal}
            >
              Refine Access
            </button>
          </div> */}

          <Table
            columns={columns}
            dataSource={tableData}
            className="custom-table"
            pagination={false}
            bordered
          />
          <div style={{ margin: '10px auto', textAlign: 'right' }}>
            <Pagination
              total={count}
              defaultCurrent={1}
              current={currentPage}
              defaultPageSize={10}
              pageSize={pageSizeTotal}
              pageSizeOptions={['5', '10', '25', '50']}
              onChange={pageSizeChange}
              showSizeChanger={true}
            />
          </div>
        </div>
      </div>
      <Modal
        title="Refine Access"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="custom-modal"
        width={800}
        okText="Refine"
      >
        <div className="row">
          <div className="col-md-6">
            <div className="input-box mb-3">
              <label htmlFor="" className="text-dark">
                Email
              </label>{" "}
              <br />
              <input
                type="text"
                className="bordered"
                placeholder="Enter email"
                value={email}
                onChange={onChangeHandler.bind(null, setEmail)}

              />
            </div>
            <div className="input-box mb-3">
              <label htmlFor="" className="text-dark">
                Username
              </label>{" "}
              <br />
              <input
                type="text"
                className="bordered"
                placeholder="Enter username"
                value={userName}
                onChange={onChangeHandler.bind(null, setUserName)}
              />
            </div>
            <div className="input-box mb-3">
              <label htmlFor="" className="text-dark">
                Staff ID
              </label>{" "}
              <br />
              <input
                type="text"
                className="bordered"
                value={staffId}
                onChange={onChangeHandler.bind(null, setStaffId)}

                placeholder="Enter staff ID"
              />
            </div>
            <div className="input-box mb-3">
              <label htmlFor="" className="text-dark">
                Designation
              </label>{" "}
              <br />
              <input
                onChange={onChangeHandler.bind(null, setDesignation)}
                value={designation}
                type="text"
                className="bordered"
                placeholder="Enter designation"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-box mb-3">
              <label htmlFor="" className="text-dark">
                Designation Group
              </label>{" "}
              <br />
              <Select
                onChange={groupOnChange}
                placeholder="Select any"
                className="custom-select w-100 bordered"
                value={groupId ? groupId : null}
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {groupArray.map(ga => {
                  return (
                    <Option value={ga._id} key={ga._id}>{ga.title}</Option>
                  )
                })}
              </Select>
            </div>
            <div className="input-box mb-3">
              <label htmlFor="" className="text-dark">
                Reason
              </label>{" "}
              <br />
              <textarea
                value={reason}
                onChange={onChangeHandler.bind(null, setReason)}
                className="bordered"
                placeholder="Comments..."
                rows={5}
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal title={`you delete ${selectedUserName}`} open={isdeleteModalOpen} onOk={handledelOk} onCancel={handledelCancel}>
        <p>Are you sure</p>
      </Modal>

    </div>
  );
}
