import React, { useEffect, useState } from "react";
import { Table, DatePicker, Tag, Modal, Tabs } from "antd";

import AccessControl from "./access-control";
import UserManagement from "./user-management";
import RevokeAccess from "./revoke-access";
import GroupManagement from "./group-management";

export default function Access() {
  const items = [
    {
      key: "1",
      label: "Access Control",
      children: <AccessControl />,
    },
    {
      key: "2",
      label: "User Management",
      children: <UserManagement />,
    },
    {
      key: "3",
      label: "Revoke Access",
      children: <RevokeAccess />,
    },
    {
      key: "4",
      label: "Group Management",
      children: <GroupManagement />,
    },
  ];

  return (
    <section className="mt-md-3">
      <div className="container-fluid px-0">
        <div className="page-header">
          <h1>Access Control</h1>
        </div>
        <div className="chart-box pt-2">
          <Tabs className="custom-tabs" defaultActiveKey="1" items={items} />
        </div>
      </div>
    </section>
  );
}
