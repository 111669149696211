import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Table, Switch, Checkbox, Cascader } from "antd";
import AccessControl from '../../repository/access-control';
import AuthRepository from '../../repository/authRep';
async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}


let data = [
  {
    screens: "Dashboard",
    access: (<Switch checked={false} />),
  },
  {
    screens: "Ticket Screens",
    access: (<Switch checked={false} />),
  },
  {
    screens: "Settings",
    access: (<Switch checked={false} />),
  },
  {
    screens: "Jobs",
    access: (<Switch checked={false} />),
  },
  {
    screens: "Client",
    access: (<Switch checked={false} />),
  },
  {
    screens: "Periodical Reviews",
    access: (<Switch checked={false} />),
  },
  {
    screens: "Access control",
    access: (<Switch checked={false} />),
  },]

export default function RevokeAccess() {
  const { Option } = Select;

  const [groupArray, setGroupArray] = useState([]);
  const [tableData, setTableData] = useState(data)
  const [selectedId, setSelectedId] = useState('');

  const [searchGroupArray, setSearchGroupArray] = useState([]);
  const [searchUserArray, setSearchUserArray] = useState([]);
  const [searchGroupId, setSearchGroupId] = useState([]);
  const [searchUserId, setSearchUserId] = useState([]);
  const [userName, setUserName] = useState('');
  const [staffID, setStaffID] = useState('');
  const [groupId, setGroupId] = useState('');
  const [email, setEmail] = useState('');
  const [designation, setDesignation] = useState('');
  const [reason, setReason] = useState('');
  const [description, setDescription] = useState('');


  const [clientDetails, setClientDetails] = useState(false);
  const [editableFields, setEditableFields] = useState(false);
  const [issubmit, setIssubmit] = useState(false);
  const [isback, setIsback] = useState(false);

  const [documents, setDocuments] = useState(false);
  const [mandatoryStatus, setMandatoryStatus] = useState(false);
  const [isupload, setIsupload] = useState(false);
  const [isdelete, setIsdelete] = useState(false);
  const [isdownload, setIsdownload] = useState(false);

  useEffect(() => {

    getsetGroup()
  }, []);


  const getsetGroup = async () => {
    let ctr = {}
    const data = await AccessControl.getGroup(ctr);
    if (data && data?.data?.data?.length) {
      setSearchGroupArray(data.data.data)
      setGroupArray(data.data.data)
    }
  }



  let columns = [
    {
      title: "Screens",
      dataIndex: "screens",
    },
    {
      title: "Access",
      dataIndex: "access",

    },
  ];

  const onChangeHandler = (setIdentifierState, event) => {
    setIdentifierState(event?.target?.value)
  }

  const groupOnChange = async (id) => {
    setGroupId(id)
  }

  const searchGroupOnChange = async (id) => {
    let ctr = {}
    ctr.role = id
    const data = await AccessControl.analyst(ctr);
    const count = await AccessControl.analystCount(ctr);
    
    if (data?.data?.length > 0) {
      setSearchUserArray(data.data)
    } else {
      setSearchUserArray([])
    }
    setSearchGroupId(id)
    setSearchUserId('')
    updateTableData(id)
  }
  const searchUserOnChange = async (id) => {
    let ctr = {}
    ctr.id = id
    const data = await AccessControl.analyst(ctr);
    const count = await AccessControl.analystCount(ctr);
   
    data?.data?.map((a, i) => {
      setSelectedId(a._id)
      setUserName(a.user_name)
      setStaffID(a.staffId)
      setGroupId(a.role)
      setDesignation(a.designation)
      setEmail(a.email)
      setDescription(a.description)
      setReason(a.reason)

    })
    setSearchUserId(id)
  }

  const updateTableData = async (id) => {
  
    let ctr = {}
    ctr.id = id
    const groupData = await AccessControl.getGroup(ctr);
    let data = [];
    if (groupData.status_code == 200) {
      let finded = groupData.data.data

      await asyncForEach(finded, async (oneDoc) => {
        if (oneDoc.features.dashboard !== null) {
         
          let val = {


            "access": oneDoc.features.dashboard ? true : false,
            "screens": 'Dashboard'
          }
          data.push(val)
        }
        if (oneDoc.features.ticket_screens !== null) {
          let val = {
            "access": (<Switch checked={oneDoc.features.ticket_screens ? true : false} />),
            "screens": 'Ticket Screens'
          }
          data.push(val)
        }
        if (oneDoc.features.settings !== null) {
          let val = {
            "access": (<Switch checked={oneDoc.features.settings ? true : false} />),
            "screens": 'Settings'
          }
          data.push(val)
        }
        if (oneDoc.features.jobs !== null) {
          let val = {
            "access": (<Switch checked={oneDoc.features.jobs ? true : false} />),
            "screens": 'Jobs'
          }
          data.push(val)
        }
        if (oneDoc.features.client !== null) {
          let val = {
            "access": (<Switch checked={oneDoc.features.client ? true : false} />),
            "screens": 'Client'
          }
          data.push(val)
        }
        if (oneDoc.features.seriodical_reviews !== null) {
          let val = {
            "access": (<Switch checked={oneDoc.features.seriodical_reviews ? true : false} />),
            "screens": 'Periodical Reviews',
          }
          data.push(val)
        }
        if (oneDoc.features.access_ccontrol !== null) {
          let val = {
            "access": (<Switch checked={oneDoc.features.access_ccontrol ? true : false} />),
            "screens": 'Access control'
          }
          data.push(val)
        }

        if (oneDoc.client.details !== null) {
          setClientDetails(oneDoc.client.details)
        }
        if (oneDoc.client.children.editable_fields !== null) {
          setEditableFields(oneDoc.client.children.editable_fields)
        }
        if (oneDoc.client.children.submit !== null) {
          setIssubmit(oneDoc.client.children.submit)
        }
        if (oneDoc.client.children.back !== null) {
          setIsback(oneDoc.client.children.back)
        }
        if (oneDoc.documents.documents !== null) {
          setDocuments(oneDoc.documents.documents)
        }
        if (oneDoc.documents.children.mandatory_status !== null) {
          setMandatoryStatus(oneDoc.documents.children.mandatory_status)
        }
        if (oneDoc.documents.children.upload !== null) {
          setIsupload(oneDoc.documents.children.upload)
        }
        if (oneDoc.documents.children.delete !== null) {
          setIsdelete(oneDoc.documents.children.delete)
        }
        if (oneDoc.documents.children.download !== null) {
          setIsdownload(oneDoc.documents.children.download)
        }


      });
    }
    setTableData(data)
  }

  const updateOnClick = async () => {

    let obj = {
      
      staffId: staffID,
      designation: designation,
      role: groupId,
      reason: reason,
    }
    const data = await AuthRepository.updateProfileById(selectedId, obj)

  }

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <Select
              onChange={searchGroupOnChange}
              placeholder="Select group"
              className="ps-ant-dropdown"
              style={{ width: '100%' }}
              value={searchGroupId ? searchGroupId : null}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {searchGroupArray.map(m => {
                return (
                  <Option value={m._id} key={m._id}>{m.title}</Option>
                )
              })}
            </Select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <Select
              onChange={searchUserOnChange}
              placeholder="Select user"
              className="ps-ant-dropdown"
              style={{ width: '100%' }}
              value={searchUserId ? searchUserId : null}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {searchUserArray.map(mc => {
                return (
                  <Option value={mc._id} key={mc._id}>{mc.user_name}</Option>
                )
              })}
            </Select>
          </div>
        </div>
      </div>

      <div className="row px-2 mt-3">
        <div className="col-md-4">
          <div className="border-box">
            {/* <div className="input-box">
              <label htmlFor="" className="text-dark">
                Staff
              </label>{" "}
              <br />
              <Select
                placeholder="Select any"
                className="custom-select w-100 bordered"
                options={[]}
              />
            </div> */}

            <div className="input-box">
              <label htmlFor="" className="text-dark">
                Email
              </label>{" "}
              <br />
              <input type="text"
                value={email}
                disabled
                className="bordered"
                placeholder="Enter email" />
            </div>
            <div className="input-box">
              <label htmlFor="" className="text-dark">
                Username
              </label>{" "}
              <br />
              <input
                type="text"
                className="bordered"
                placeholder="Enter username"
                disabled
                value={userName}
              />
            </div>
            <div className="input-box">
              <label htmlFor="" className="text-dark">
                Designation
              </label>{" "}
              <br />
              <input
                value={designation}
                onChange={onChangeHandler.bind(null, setDesignation)}
                type="text"
                className="bordered"
                placeholder="Enter designation"
              />
            </div>
            <div className="input-box">
              <label htmlFor="" className="text-dark">
                Designation Group
              </label>{" "}
              <br />
              <Select
                onChange={groupOnChange}
                placeholder="Select designation Group"
                className="ps-ant-dropdown"
                style={{ width: '100%' }}
                value={groupId ? groupId : null}
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {groupArray.map(m => {
                  return (
                    <Option value={m._id} key={m._id}>{m.title}</Option>
                  )
                })}
              </Select>
            </div>
            <div className="input-box">
              <label htmlFor="" className="text-dark">
                Staff ID
              </label>{" "}
              <br />
              <input
                type="text"
                value={staffID}
                disabled
                className="bordered"
                placeholder="Enter staff ID"
              />
            </div>
            <div className="input-box">
              <label htmlFor="" className="text-dark">
                Reason
              </label>{" "}
              <br />
              <textarea
                className="bordered"
                value={reason}
                onChange={onChangeHandler.bind(null, setReason)}
                placeholder="Comments..." rows={5} />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="border-box">
            <h5 className="mb-4">Granted Screens</h5>

            <Table
              columns={columns}
              dataSource={tableData}
              className="custom-table"
              pagination={false}
              bordered
            />

          </div>
        </div>
        <div className="col-md-4">
          <div className="border-box">
            <h6 className="mb-4">Sub Screens and Functions</h6>
            <div className="col-lg-12" key={1} style={{ padding: 10 }}>
              <div className="row no-gutters">
                <div className="col-lg-12">
                  <Checkbox
                    checked={clientDetails}
                  >Client Details</Checkbox>
                </div>
                <br />
                {clientDetails ?
                  <div className="d-flex flex-column ms-5">
                    <Checkbox
                      checked={editableFields}
                    >Editable Fields</Checkbox>
                    <Checkbox
                      checked={issubmit}
                    >Submit</Checkbox>
                    <Checkbox
                      checked={isback}
                    >Back</Checkbox>
                  </div>
                  : <></>}
              </div>
            </div>
            <div className="col-lg-12" key={1} style={{ padding: 10 }}>
              <div className="row no-gutters">
                <div className="col-lg-12">
                  <Checkbox
                    checked={documents}
                  >Documents</Checkbox>
                </div>
                <br />
                {documents ?
                  <div className="d-flex flex-column ms-5">

                    <Checkbox
                      checked={mandatoryStatus}
                    >Mandatory Status</Checkbox>
                    <Checkbox
                      checked={isupload}
                    >Upload</Checkbox>
                    <Checkbox
                      checked={isdelete}
                    >Delete</Checkbox>
                    <Checkbox
                      checked={isdownload}
                    >Download</Checkbox>
                  </div>
                  : <></>}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button onClick={updateOnClick} className="red-btn mt-5 text-end" type="button">
                Revoke Access
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
