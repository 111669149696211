import React, { useEffect, useState } from 'react';
import { Modal, Spin, notification, Pagination, Tabs, Select, Radio, Checkbox, Row, Col } from 'antd';
import AccessControl from '../../repository/access-control';
import authRep from '../../repository/authRep';

let regEmail =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export default function UserManagement() {
  const { Option } = Select;
  const [errors, setErrors] = useState({});

  const [groupArray, setGroupArray] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [user, setUser] = useState({});

  const [isdashboard, setIsdashboard] = useState(false);
  const [isticketScreens, setIsticketScreens] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const [isjobs, setIsjobs] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [isSeriodicalReviews, setIsSeriodicalReviews] = useState(false);
  const [isAccessControl, setIsAccessControl] = useState(false);


  const [clientDetails, setClientDetails] = useState(false);
  const [editableFields, setEditableFields] = useState(false);
  const [issubmit, setIssubmit] = useState(false);
  const [isback, setIsback] = useState(false);

  const [documents, setDocuments] = useState(false);
  const [mandatoryStatus, setMandatoryStatus] = useState(false);
  const [isupload, setIsupload] = useState(false);
  const [isdelete, setIsdelete] = useState(false);
  const [isdownload, setIsdownload] = useState(false);

  const [staffName, setStaffName] = useState('');
  const [designation, setDesignation] = useState('');
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [groupId, setGroupId] = useState('');
  const [staffId, setStaffId] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    (async () => {
      let local = localStorage.getItem('session')
      let session = JSON.parse(local)
      setUser(session)

    }
    )()
    getsetGroup()
  }, []);


  const getsetGroup = async () => {
    let ctr = {}
    const data = await AccessControl.getGroup(ctr);
    if (data && data?.data?.data?.length) {
      setGroupArray(data.data.data)
    }
  }
  const onChangeHandler = (setIdentifierState, event) => {
    let errorObj = { ...errors }
    errorObj[event?.target?.name] = ''
    setIdentifierState(event?.target?.value)
    setErrors(errorObj)
  }

  const groupOnChange = async (id) => {
    let errorObj = { ...errors }
    errorObj['groupId'] = ''
    setGroupId(id)
    setErrors(errorObj)
  }
  const registerEmailOnChange = (email) => {
    let errorObj = { ...errors }
    if (email) {
      if (regEmail.test(email)) {
        errorObj['email'] = '';
      } else {
        errorObj['email'] = "Please enter valid email"
      }
    } else { errorObj['email'] = ''; }
    setEmail(email)
    setErrors(errorObj)
  };

  const saveOnClick = async () => {
    if (title && description && user) {

      let clientObj = {
        details: clientDetails,
        children: {
          editable_fields: editableFields,
          submit: issubmit,
          back: isback
        }
      }

      let documentsObj = {
        documents: documents,
        children: {
          mandatory_status: mandatoryStatus,
          upload: isupload,
          delete: isdelete,
          download: isdownload
        },

      }

      let featuresObj = {
        dashboard: isdashboard,
        ticket_screens: isticketScreens,
        settings: isSettings,
        jobs: isjobs,
        client: isClient,
        seriodical_reviews: isSeriodicalReviews,
        access_ccontrol: isAccessControl

      }

      let obj = {
        "title": title,
        "description": description,
        "isDelete": false,
        "createdby": user ? user.name : '',
        "client": clientObj,
        "documents": documentsObj,
        "features": featuresObj
      }

      const data = await AccessControl.saveGroup(obj);
      if (data.status_code == 200) {
        notification.success({
          message: 'Group Added Successfully.',

        });
      } else if (data.status_code == 403) {
        notification.error({
          message: 'Already Existing',
        });
      }
      getsetGroup()
    } else {
      notification.error({
        message: `Please enter the valid data`,

      });
    }

  }

  const saveUserOnClick = async () => {
    if (title) {
      let obj = {
        name: staffName,
        user_name: userName,
        staffId: staffId,
        designation: designation,
        password: password,
        email: email,
        role: groupId,
        isDelete: false,
      }
      const data = await authRep.signup(obj);
      if (data.status_code == 200) {
        notification.success({
          message: 'Group Added Successfully.',

        });
      } else if (data.status_code == 403) {
        notification.error({
          message: 'Already Existing',
        });
      }
    } else {
      notification.error({
        message: `Please enter the valid data`,

      });
    }
  }
  return (
    <div>
      <div className="border-box mb-4">
        <h5 className="mb-4">Create Group</h5>
        <div className="row">
          <div className="col-md-6">
            <div className="input-box">
              <label htmlFor="" className="text-dark">
                Title | Designation
              </label>{" "}
              <br />
              <input
                className="bordered"
                type="text"
                value={title}
                placeholder="Enter the title"
                onChange={onChangeHandler.bind(null, setTitle)}
              />

            </div>
            <div className="input-box">
              <label htmlFor="" className="text-dark">
                Description
              </label>{" "}
              <br />
              <textarea
                className="bordered"
                placeholder="Enter description here..."
                rows={5}
                value={description}
                onChange={onChangeHandler.bind(null, setDescription)}
              />
            </div>
            <button onClick={saveOnClick} className="primary-btn" type="button">
              Create
            </button>
          </div>


        </div>
      </div>
      <div className="border-box">
        <h4 className="mb-4">Create User</h4>
        <div className="row">
          <div className="col-md-6">
            <div className="input-box">
              <label htmlFor="" className="text-dark">
                Staff
              </label>{" "}
              <br />
              <input
                className="bordered"
                type="text"
                value={staffName}
                placeholder="Enter staff"
                onChange={onChangeHandler.bind(null, setStaffName)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-box">
              <label htmlFor="" className="text-dark">
                Designation
              </label>{" "}
              <br />
              <input
                type="text"
                className="bordered"
                placeholder="Enter designation"
                value={designation}
                onChange={onChangeHandler.bind(null, setDesignation)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-box">
              <label htmlFor="" className="text-dark">
                Email
              </label>{" "}
              <br />
              <input
                type="text"
                className="bordered"
                placeholder="Enter email"
                value={email}
                onChange={(e) => registerEmailOnChange(e.target.value)}
              />
              {errors['email'] &&
                <span style={{ color: 'red' }}>{errors['email']}</span>
              }

            </div>
          </div>
          <div className="col-md-6">
            <div className="input-box">
              <label htmlFor="" className="text-dark">
                Designation Group
              </label>{" "}
              <br />
              <Select
                onChange={groupOnChange}
                placeholder="Select any"
                className="custom-select w-100 bordered"
                value={groupId ? groupId : null}
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {groupArray.map(ga => {
                  return (
                    <Option value={ga._id} key={ga._id}>{ga.title}</Option>
                  )
                })}
              </Select>

            </div>
          </div>
          <div className="col-md-6">
            <div className="input-box">
              <label htmlFor="" className="text-dark">
                Username
              </label>{" "}
              <br />
              <input
                type="text"
                className="bordered"
                placeholder="Enter username"
                value={userName}
                onChange={onChangeHandler.bind(null, setUserName)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-box">
              <label htmlFor="" className="text-dark">
                Staff ID
              </label>{" "}
              <br />
              <input
                type="text"
                className="bordered"
                placeholder="Enter staff ID"
                value={staffId}
                onChange={onChangeHandler.bind(null, setStaffId)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-box">
              <label htmlFor="" className="text-dark">
                Password
              </label>{" "}
              <br />
              <input
                type="text"
                className="bordered"
                placeholder="Enter password"
                value={password}
                onChange={onChangeHandler.bind(null, setPassword)}
              />
            </div>
          </div>
        </div>
        <button onClick={saveUserOnClick} className="primary-btn" type="button">
          Create
        </button>
      </div>
    </div>
  );
}
