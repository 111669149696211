import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'  
import { useSelector, useDispatch } from 'react-redux'

export default function Index() {
  const isLogged = useSelector((state) => state.auth.isLogged)
  
  if (isLogged) {
    return <Navigate to='/app/dashboard' replace />;
  }

  return (
    <div className='auth-layout'>
        <div className="auth-card">
            <Outlet />
        </div>
    </div>
  )
}
