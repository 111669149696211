import React, { useEffect, useState } from 'react'
import { Table, Tag, DatePicker, Modal } from 'antd';
import { ProColumns } from '@ant-design/pro-components';
import {
  EditableProTable,
  ProCard,
  ProFormField,
} from '@ant-design/pro-components';

import { Link } from 'react-router-dom';
import moment from 'moment';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

import { sendNotify } from '../helper/notification';
import jobRep from '../repository/jobRep';

const { RangePicker } = DatePicker;

const defaultData = new Array(20).fill(1).map((_, index) => {
  return {
    clientName: `Client ${index + 1}`,
    id: `16930${index}`,
    riskRating: 4 + index,
    onboardDate: moment(Date.now()).format("MM-DD-YYYY"),
    lastReviewDate: moment(Date.now()).format("DD-MM-YYYY"),
    reviewPeriod: '',
    nextReviewDate: moment(Date.now()).format("DD-MM-YYYY"),
    Analyst: '',
    age: 10 + index,
    changeBy: 'Kerner Norland',
  };
});

export default function Periodic() {
  const [tableData, setTableData] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [editableKeys, setEditableRowKeys] = useState(() =>
    defaultData.map((item) => item.id),
  );
  const [dataSource, setDataSource] = useState([]);

  const columns = [
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      valueType: '',
    },
    {
      title: 'Client ID',
      dataIndex: 'id',
      valueType: '',
    },
    {
      title: 'Risk rating',
      dataIndex: 'riskRating',
      render: (_, { riskRating }) => (
        <>
          {riskRating.map((s) => {
            let color = '';
            switch (s) {
              case 'Medium':
                color = 'gold'
                break;
              case 'Low':
                color = 'green'
                break;
              case 'High':
                color = 'red'
                break;
            }
            return (
              <Tag color={color} key={s}>{s}</Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Onboarded Date',
      dataIndex: 'onboardDate',
      width: 150,
    },
    {
      title: 'Last Review Date',
      dataIndex: 'lastReviewDate',
      width: 150,
    },
    {
      title: 'Review Period',
      dataIndex: 'reviewPeriod',
      valueType: '',
    },
    {
      title: 'Next review Date',
      dataIndex: 'nextReviewDate',
      valueType: '',
    },
    {
      title: 'Analyst',
      dataIndex: 'analyst',
      valueType: '',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      valueType: '',
      render: (_, { age, riskRating }) => (
        (riskRating == "High" ?
          (age - 180 > 0 ? < p className='m-0' style={{ color: 'red' }}> {age - 180} </p > :
            < p className='m-0' style={{ color: 'green' }}>{age - 180}</p>) :
          (age - 365 > 0 ? < p className='m-0' style={{ color: 'red' }}> {age - 365} </p > :
            < p className='m-0' style={{ color: 'green' }}>{age - 365}</p>)
        ))
    },
    {
      title: 'Last Changed By',
      dataIndex: 'changeBy',
      valueType: '',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text, record, index) => (
        <div className="d-flex" key={index}>
          <button className='action-btn' onClick={() => getId(record)}><i className="far fa-edit"></i></button>
        </div>
      ),
    }
  ];
  useEffect(() => {
    getListData();
  }, [])

  const getId = (data) => {
    setSelectedData(data);
    setUpdateModal(true);
  }
  const cancelHandle = () => {
    setUpdateModal(false);
  }
  const updateTicket = () => {
    const updateBody = {
      onBoardOn: new Date(selectedData.onboardDate),
      lastReviewOn: new Date(selectedData.lastReviewDate),
      ticketId: selectedData.ticketId
    }
    jobRep.updatePeriodicList(updateBody).then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.message);
        } else {
          sendNotify('success', response?.message);
          setUpdateModal(false);
          getListData();
        }
      }
    });

  }
  const getListData = () => {
    jobRep.getPeriodicList().then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.message)
        } else {
          if (response && response?.data.length > 0) {
            let obj = response?.data.map((data, i) => {
             
              return {
                clientName: data.ticketData.client_name,
                id: data.ticketData.client_id,
                riskRating: [data.risk_level],
                onboardDate: data.onBoardOn ? moment(data.onBoardOn).format("YYYY/MM/DD") : "1990/09/01",
                lastReviewDate: data.lastReviewOn ? moment(data.lastReviewOn).format("YYYY/MM/DD") : "1990/09/01",
                reviewPeriod: data.risk_level == "High" ? "6 Months" : "12 Months",
                nextReviewDate: data.lastReviewOn
                  ? (data.risk_level == "High" ? moment(data.lastReviewOn).add(6, "months").format("YYYY/MM/DD") : moment(data.lastReviewOn).add(12, "months").format("YYYY/MM/DD"))
                  : data.onBoardOn
                    ? (data.risk_level == "High" ? moment(data.lastReviewOn).add(6, "months").format("YYYY/MM/DD") : moment(data.lastReviewOn).add(12, "months").format("YYYY/MM/DD"))
                    : "--",
                analyst: data.analyst.name,
                age: data?.lastReviewOn ?
                  moment().diff(moment(data?.lastReviewOn), 'days') : moment().diff(moment(data?.onBoardOn), 'days'),
                changeBy: data.changedby.name,
                ticketId: data.ticketId
              }
            })
            setDataSource(obj)
          }
        }
      }
    })
      .catch(error => (sendNotify('error', JSON.stringify(error))));
  }
  const dataExtraction = () => {
    let payload = {
      "startDate": startDate,
      "endDate": endDate,
      "mode": "periodic"
    }
    jobRep.jobExtraction(payload).then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.data?.message)
        } else {
          sendNotify('success', response?.message)
         
          let obj = response?.data.map((data, i) => {
            return {
              clientName: data.ticketData.client_name,
              id: data.ticketData.client_id,
              riskRating: [data.risk_level],
              onboardDate: data.onBoardOn ? moment(data.onBoardOn).format("YYYY/MM/DD") : "1990/09/01",
              lastReviewDate: data.lastReviewOn ? moment(data.lastReviewOn).format("YYYY/MM/DD") : "1990/09/01",
              reviewPeriod: data.risk_level == "High" ? "6 Months" : "12 Months",
              nextReviewDate: data.lastReviewOn
                ? (data.risk_level == "High" ? moment(data.lastReviewOn).add(6, "months").format("YYYY/MM/DD") : moment(data.lastReviewOn).add(12, "months").format("YYYY/MM/DD"))
                : data.onBoardOn
                  ? (data.risk_level == "High" ? moment(data.lastReviewOn).add(6, "months").format("YYYY/MM/DD") : moment(data.lastReviewOn).add(12, "months").format("YYYY/MM/DD"))
                  : "--",
              analyst: data.analyst.name,
              age: data?.lastReviewOn ?
                moment().diff(moment(data?.lastReviewOn), 'days') : moment().diff(moment(data?.onBoardOn), 'days'),
              changeBy: data.changedby.name,
              ticketId: data.ticketId
            }
          })
          let objs = response?.data.map((data, i) => {
            return {
              Client_Name: data.ticketData.client_name,
              id: data.ticketData.client_id,
              riskRating: [data.risk_level],
              onboardDate: data.onBoardOn ? moment(data.onBoardOn).format("YYYY/MM/DD") : "1990/09/01",
              lastReviewDate: data.lastReviewOn ? moment(data.lastReviewOn).format("YYYY/MM/DD") : "1990/09/01",
              Review_Period: data.risk_level == "High" ? "6 Months" : "12 Months",
              Next_Review_Date: data.lastReviewOn
                ? (data.risk_level == "High" ? moment(data.lastReviewOn).add(6, "months").format("YYYY/MM/DD") : moment(data.lastReviewOn).add(12, "months").format("YYYY/MM/DD"))
                : data.onBoardOn
                  ? (data.risk_level == "High" ? moment(data.lastReviewOn).add(6, "months").format("YYYY/MM/DD") : moment(data.lastReviewOn).add(12, "months").format("YYYY/MM/DD"))
                  : "--",
              Analyst: data.analyst.name,
              Age: data?.lastReviewOn ?
                moment().diff(moment(data?.lastReviewOn), 'days') : moment().diff(moment(data?.onBoardOn), 'days'),
              Change_By: data.changedby.name,
              TicketId: data.ticketId
            }
          })
         
          exportToExcel(objs, 'periodic_review.xlsx');
          setTableData(obj)
        }
      }
    })
  }
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };
  function exportToExcel(data, fileName) {
    const workbook = XLSX.utils.book_new();

    // Create a worksheet and add data to it
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Convert the workbook to a data URL
    const excelDataUrl = XLSX.write(workbook, { bookType: 'xlsx', type: 'base64' });

    // Create a data URI for the Excel file
    const dataUri = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${excelDataUrl}`;

    // Create an anchor element for downloading
    const a = document.createElement('a');
    a.href = dataUri;
    a.download = fileName;

    // Trigger a click event to initiate the download
    a.click();
  }

  return (
    <section className='mt-md-3'>
      <div className="page-header">
        <h1>Periodic List</h1>
      </div>
      <div className="page-header flex-md-row flex-column">
        <div className="custom-search my-1">
          <input type="text" placeholder='Search by periodic name/periodic ID' />
          <button><i className="far fa-search"></i></button>
        </div>
        <div className='d-flex mb-3'>
          <RangePicker onCalendarChange={getListData} onChange={handleDateRangeChange} className='custom-datepicker' />
          <button className='primary-btn ms-2' onClick={() => dataExtraction()}><i className="far fa-file-export me-0 me-md-1"></i> <span className='d-none d-md-inline'>Extract</span></button>
        </div>
      </div>

      <Table columns={columns} dataSource={dataSource} className='custom-table' loading={false} />
      {selectedData &&
        <Modal title="Update Record" centered width={600} className='custom-modal' open={updateModal} onOk={updateTicket}
          onCancel={cancelHandle} okText={'Update'}>
          <div className='modal-auth-form'>
            <div className={`input-box`}>
              <label htmlFor="risk_level">Onboarded Date</label>
              <div className='input-field'>
                <DatePicker
                  defaultValue={dayjs(selectedData.onboardDate, "YYYY/MM/DD")}
                  format={"YYYY/MM/DD"}
                  onChange={(date, dateString) => {
                    setSelectedData({
                      ...selectedData,
                      onboardDate: dateString
                    })
                  }}
                />
              </div>
            </div>
            <div className={`input-box`}>
              <label htmlFor="risk_level">Last Review Date</label>
              <div className='input-field'>
                <DatePicker
                  defaultValue={dayjs(selectedData.lastReviewDate, "YYYY/MM/DD")}
                  format={"YYYY/MM/DD"}
                  onChange={(date, dateString) => {
                    setSelectedData({
                      ...selectedData,
                      lastReviewDate: dateString
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      }
    </section>
  )
}
