import Repository, { apiUrl } from './index';
import { jsonToQuery } from '../helper/auth';
class MenuRepository {
    constructor(callback) {
        this.callback = callback;
    }

    async getMenu() {
        let url = `${apiUrl}/usermenu/get`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
   

    async getGroup(payload) {
        let url = `${apiUrl}/group/get`;
        url += jsonToQuery(payload);
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }


    
    async analyst(payload) {
        let url = `${apiUrl}/auth/getanalyst`;
        url += jsonToQuery(payload);
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

    async analystCount(payload) {
        let url = `${apiUrl}/auth/count-analyst`;
        url += jsonToQuery(payload);
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

    async saveGroup(formdata) {
        let url = `${apiUrl}/group/create`;
        const reponse = await Repository.post(url, formdata)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                return error.response.data;
            });
        return reponse;
    }

    async updateStatus(data) {
        let url = `${apiUrl}/group/updateStatus`;
        const reponse = await Repository.put(url, data)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                return error.response.data;
            });
        return reponse;
    }

    async editgroup(groupId, formdata) {
        let url = `${apiUrl}/group/update/${groupId}`;
        const reponse = await Repository.put(url, formdata)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                return error.response.data;
            });
        return reponse;
    }

    
   
}

export default new MenuRepository();