import React, { useEffect, useState, useRef } from 'react'
import { Select, DatePicker, Upload, Modal, Image, Button, Table, Tag } from 'antd';
import { useParams } from "react-router-dom";

import { Link } from 'react-router-dom';
import moment from 'moment';

import clientRep from '../../repository/clientRep';
import { sendNotify } from '../../helper/notification'

import JSZip from 'jszip';
import 'jszip/dist/jszip.min.js'; // Import the JSZip library


export default function EditClient() {
    let { id } = useParams();
    const uploader = useRef();

    const { confirm } = Modal;

    let data = localStorage.getItem('session')
    let session = JSON.parse(data)
    let assignedBy = session.id
    const [isUploade, setIsUploade] = useState(false);
    const [selectedTab, setSelectedTab] = useState(1);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [uploadOpen, setUploadOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState();
    const [mutipleDocumentName, setMultipleDocumentName] = useState('');
    const [expiryDate, setExpiryDate] = useState(null);
    const [clientDetails, setClientDetails] = useState({})
    const [formValues, setFormValues] = useState({})
    const [tableData, setTableData] = useState([]);
    const [popupData, setPopupData] = useState([]);
    const [documentName, setDocumentName] = useState();
    const [mutliple, setMutliple] = useState(false);
    const [clientArray, setClientArray] = useState([])
    const [selectedFile, setSelectedFile] = useState(null);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            const idArray = selectedRows.map(row => row.name);
            setClientArray({
                id: clientDetails.ticketData.client_id,
                doc_name: mutipleDocumentName,
                name: idArray
            })
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
    };

    const handleCancel = () => setPreviewOpen(false);

    const handleUploadCancel = () => setUploadOpen(false);

    const handleMutipleCancel = () => setMutliple(false);

    useEffect(() => {
        getclientDetails();
    }, [])

    const getclientDetails = () => {
        clientRep.getClientsById(id).then(response => {
            if (response) {
                if (response.error) {
                    sendNotify('error', response.error?.response?.message)
                } else {
                    let objs = response?.data[0]?.document.map((data, i) => {
                        return {
                            key: i,
                            id: data._id,
                            document_name: data?.document_name,
                            status: data?.status,
                            mandatory: data?.mandatory,
                            updatedAt: data?.updatedAt,
                            expiry: data?.expiry_date,
                            last_uploaded: data?.last_uploaded?.name,
                            data: data
                        }
                    })
                    setTableData(objs)
                    setClientDetails(response.data[0])
                }
            }
        })
            .catch(error => (sendNotify('error', JSON.stringify(error))));
    }

    const downloadSelected = (mode) => {
        var sendItem;
        if (mode === 'SELECTED') {
            sendItem = clientArray
        } else if (mode === 'ALL') {
            const namesArray = popupData.map(item => item.name);
            var payload = {
                id: clientDetails.ticketData.client_id,
                doc_name: mutipleDocumentName,
                name: namesArray
            }
            sendItem = payload
        }
        clientRep.downloadSelected(sendItem).then(response => {
            if (response) {
                if (response.error) {
                    sendNotify('error', response.error?.response?.message)
                }
                else {
                    sendNotify('success', response?.message)
                    handleMutipleCancel();
                    const { docName, images } = response.data[0];
                    const zip = new JSZip();
                    images.forEach((imageInfo) => {
                        const { imageName, base64Content } = imageInfo;
                        if (mode === 'ALL') {
                            zip.file(`${clientDetails.ticketData.client_id}/${docName}/${imageName}.pdf`, base64Content, { base64: true });
                        } else if (mode === 'SELECTED') {
                            zip.file(`${docName}/${imageName}.pdf`, base64Content, { base64: true });

                        }
                    });
                    zip.generateAsync({ type: 'blob' }).then((content) => {
                        // Create a link element to trigger the download
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(content);
                        if (mode === 'ALL') {
                            link.download = `${clientDetails.ticketData.client_id}/${docName}.zip`;
                        } else if (mode === 'SELECTED') {
                            link.download = `${docName}.zip`;
                        }
                        link.click();
                    });
                }
            }
        })
            .catch(error => (sendNotify('error', JSON.stringify(error))));
    }

    const downloadLatest = (data) => {
        var payload = {
            jobId: data._id,
            clientId: clientDetails.ticketData.client_id,
        }
        clientRep.downloadLatest(payload).then(response => {
            if (response) {
                if (response.error) {
                    sendNotify('error', response.error?.response?.message)
                }
                else {
                    sendNotify('success', response?.message)
                    var multipleImage = response.data
                    const zip = new JSZip();
                    multipleImage.map((arrayOfData) => {

                        zip.file(`${clientDetails.ticketData.client_id}/${arrayOfData.document_name}/${arrayOfData.latestFile.name}.pdf`, arrayOfData.latestFile.base64Content, { base64: true });
                    })
                    zip.generateAsync({ type: 'blob' }).then((content) => {
                        // Create a link element to trigger the download
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(content);
                        link.download = `${clientDetails.ticketData.client_id}/LatestDocuments.zip`;
                        link.click();
                    });
                }
            }
        })
            .catch(error => (sendNotify('error', JSON.stringify(error))));
    }

    const submitForm = () => {
        let payload = {
            clientId: id,
            comments: formValues.comments,
            contact_person1: formValues.contact_person1,
            contact_person2: formValues.contact_person2,
            contact_person3: formValues.contact_person3,
            nationality: formValues.nationality,
            passport_number: formValues.passport_number,
            pep: formValues.pep,
            risk_level: formValues.risk_level,
            next_review: formValues.next_review
        }
        clientRep.uploadClient(payload).then(response => {
            if (response) {
                if (response.error) {
                    sendNotify('error', response.error?.response?.data?.message)
                } else {
                    sendNotify('success', response?.message)
                    getclientDetails();
                }
            }
        })
    }

    const customRequest = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

    };

    const submitImages = async () => {
        setIsUploade(true)
        var file = await customRequest(selectedFile)
        const parts = file.split(',');
        const base64Content = parts[1];
        let documentsData = {
            uploaded_by: assignedBy,
            expiry_date: expiryDate ? expiryDate : null,
            file: base64Content,
            name: documentName
        }
        let data = localStorage.getItem('session')
        let session = JSON.parse(data)
        var payload = {
            "documents": [documentsData],
            "document_name": mutipleDocumentName,
            "jobId": clientDetails._id,
            "clientId": id,
            "document": "status",
            "last_uploaded": session.id
        }
        if (payload.document_name) {
            clientRep.uploadClientdocuments(payload).then(response => {
                if (response) {
                    if (response.error) {
                        sendNotify('error', response.error?.response?.message)
                    } else {
                        sendNotify('success', response?.message)
                        handleUploadCancel();
                        handleMutipleCancel();
                        getclientDetails();
                        setSelectedFile(null)
                    }
                }
            })
                .catch(error => (sendNotify('error', JSON.stringify(error))));
        }
        else {
            sendNotify('error', "Missing Document Name")
        }
    }

    const individualData = (data, mode) => {
        if (mode === 'create') {
            setMultipleDocumentName(data.document_name)
            setMutliple(true);
            let objs = data?.documents.map((record, i) => {
                return {
                    key: i,
                    id: record?._id,
                    name: record?.name,
                    expiry_date: record?.expiry_date,
                    last_uploaded: record?.uploaded_by.name,
                    uploaded_date: record?.uploaded_date,
                    data: record
                }
            })
            setPopupData(objs)
        }
        else if (mode === 'download') {
            if (data?.documents[0]) {
                downloadImage(data?.documents[0], "download", data?.document_name)
            } else {
                sendNotify('error', "No Data")
            }
        }
        else if (mode === 'downloadall') {
            downloadLatest(data)
        }
    }

    let columns = [
        {
            title: 'Document',
            dataIndex: 'document_name',
            render: (_, { document_name }) => (
                <div style={{ textTransform: "capitalize" }}>{document_name ? document_name : "--"}</div>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (_, { status }) => (
                <Tag color={status === true ? 'success' : 'error'}>{status === true ? 'Available' : 'Not available'}</Tag>
            )
        },
        {
            title: 'Mandatory',
            dataIndex: 'mandatory',
            render: (_, { mandatory }) => (
                <Tag color={mandatory === true ? 'success' : 'error'}>{mandatory === true ? 'Yes' : 'No'}</Tag>
            )
        },
        {
            title: 'Uploaded Date',
            dataIndex: 'updatedAt',
            render: (_, { updatedAt }) => (
                <div>{updatedAt ? moment(updatedAt).format('lll') : "--"}</div>
            )
        },
        {
            title: 'Expiry Date',
            dataIndex: 'expiry',
            render: (_, { expiry }) => (
                <div>{expiry ? moment(expiry).format('lll') : "--"}</div>
            )
        },
        {
            title: 'Uploaded By',
            dataIndex: 'updatedAt',
            render: (_, { last_uploaded }) => (
                <div>{last_uploaded ? last_uploaded : "--"}</div>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, { data }) => (
                <div className='d-flex'>
                    <button onClick={() => individualData(data, "create")} className='action-btn'><i className="far fa-eye"></i></button>
                    <button onClick={() => individualData(data, "download")} className='action-btn'><i className="far fa-cloud-download"></i></button>
                </div>
            )
        },

    ];

    const deleteDocuments = async (data) => {
        var payload = {
            id: data._id
        }
        clientRep.deleteClientdocuments(payload).then(response => {
            if (response) {
                if (response.error) {
                    sendNotify('error', response.error?.response?.message)
                } else {
                    sendNotify('success', response?.message)
                    handleMutipleCancel();
                    getclientDetails();
                }
            }
        })
            .catch(error => (sendNotify('error', JSON.stringify(error))));

    }

    const downloadImage = async (data, mode, name) => {
        let payload = {
            "doc_name": mutipleDocumentName ? mutipleDocumentName : name,
            "id": clientDetails.ticketData.client_id,
            "name": data.name
        }
        console.log(data, payload)
        clientRep.downloadFromS3(payload).then(response => {
            if (response) {
                if (response.error) {
                    sendNotify('error', response.error?.response?.message)
                } else {
                    sendNotify('success', response?.message)
                    if (mode === 'download') {
                        handleMutipleCancel();
                        getclientDetails();
                        downloadBase64Image(response.data, data.name);
                    }
                    console.log(response.data,"bhjbjbjbj")
                    setPreviewImage(response.data)
                }
            }
        })
            .catch(error => (sendNotify('error', JSON.stringify(error))));
    }

    const downloadBase64Image = (base64Data, fileName) => {
        const blob = base64ToBlob(base64Data);
        const blobUrl = URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = fileName;
        link.click();

        // Clean up by revoking the Blob URL
        URL.revokeObjectURL(blobUrl);
    };

    const base64ToBlob = (base64Data) => {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: 'application/pdf' }); // Adjust the type accordingly
    };

    let documentcolumns = [
        {
            title: 'Document Name',
            dataIndex: 'name',
            render: (_, { name }) => (
                <div style={{ textTransform: "capitalize" }}>{name ? name : "--"}</div>
            )
        },
        {
            title: 'Uploaded Date',
            dataIndex: 'uploaded_date',
            render: (_, { uploaded_date }) => (
                <div>{uploaded_date ? moment(uploaded_date).format('lll') : "--"}</div>
            )
        },
        {
            title: 'Expiry Date',
            dataIndex: 'expiry_date',
            render: (_, { expiry_date }) => (
                <div>{expiry_date ? moment(expiry_date).format('lll') : "--"}</div>
            )
        },
        {
            title: 'Uploaded By',
            dataIndex: 'last_uploaded',
            render: (_, { last_uploaded }) => (
                <div>{last_uploaded ? last_uploaded : "--"}</div>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, { data }) => (
                <div className='d-flex'>
                    <button className='action-btn' onClick={() => confirmLogout(data)} ><i className="far fa-trash"></i></button>
                    <button onClick={() => { downloadImage(data, "preview", null); setPreviewOpen(true) }} className='action-btn'><i className="far fa-eye"></i></button>
                    <button onClick={() => { downloadImage(data, "download", null) }} className='action-btn'><i className="far fa-cloud-download"></i></button>
                </div >
            )
        }
    ];

    const confirmLogout = (data) => {
        confirm({
            title: <h5>Confirm Delete</h5>,
            content: <p>Are you sure you want to Delete?</p>,
            className: 'custom-confirm',
            centered: true,
            okText: 'Logout',
            onOk() {
                deleteDocuments(data);
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };

    return (
        <section className='mt-md-3'>
            <div className="page-header">
                <h1><span>Job&nbsp;/&nbsp;</span>{id}</h1>
            </div>
            <div className='chart-box pt-3'>
                <div className='tab-wrapper mb-4'>
                    <button onClick={() => setSelectedTab(1)} className={selectedTab == 1 ? 'active' : ''}>Detail</button>
                    <button onClick={() => setSelectedTab(2)} className={selectedTab == 2 ? 'active' : ''}>Documents</button>
                </div>
                <div className={`tab-panel ${selectedTab == 1 ? 'active' : ''}`}>
                    <div className="gird-view mb-4">
                        <div className='job-cols'>
                            <label className='mb-2'>Client ID</label>
                            <h5>{clientDetails?.ticketData?.client_id}</h5>
                        </div>
                        <div className='job-cols'>
                            <label className='mb-2'>Client Name</label>
                            <h5>{clientDetails?.ticketData?.client_name}</h5>
                        </div>
                        {/* <div className='job-cols'>
                            <label className='mb-2'>Passport Number</label>
                            {clientDetails?.passport_number ? <h5>{clientDetails?.passport_number}</h5> :
                                <input type="text" className='custom-input' onChange={(e) => setFormValues({
                                    ...formValues,
                                    'passport_number': e.target.value
                                })} placeholder='Enter passport number' />}

                        </div>
                        <div className='job-cols'>
                            <label className='mb-2'>Nationality</label>
                            {clientDetails?.nationality ? <h5>{clientDetails?.nationality}</h5> :
                                <input type="text" className='custom-input' placeholder='Enter nationality' onChange={(e) => setFormValues({
                                    ...formValues,
                                    'nationality': e.target.value
                                })} />}
                        </div> */}
                        <div className='job-cols'>
                            <label className='mb-2'>Client Category</label>
                            <h5>{clientDetails?.ticketData?.client_category}</h5>
                        </div>
                        <div className='job-cols'>
                            <label className='mb-2'>Partner</label>
                            <h5>{clientDetails?.ticketData?.partner}</h5>
                        </div>
                        <div className='job-cols'>
                            <label className='mb-2'>Status</label>
                            <h5>{clientDetails?.status}</h5>
                        </div>
                        {/* <div className='job-cols'>
                            <label className='mb-2'>PEP</label>
                            {clientDetails?.pep ? <h5>{clientDetails?.pep}</h5> :
                                <input type="text" className='custom-input' placeholder='Enter pep' onChange={(e) => setFormValues({
                                    ...formValues,
                                    'pep': e.target.value
                                })} />}
                        </div> */}
                        <div className='job-cols'>
                            <label className='mb-2'>Risk</label>
                            {clientDetails?.risk_level ? <h5>{clientDetails?.risk_level}</h5> :
                                <Select
                                    className='custom-select'
                                    style={{ width: 250 }}
                                    onChange={(value) => setFormValues({ ...formValues, 'risk_level': value })}
                                    placeholder="Select any status"
                                    options={[
                                        {
                                            value: 'Low',
                                            label: 'Low',
                                        },
                                        {
                                            value: 'Medium',
                                            label: 'Medium',
                                        },
                                        {
                                            value: 'High',
                                            label: 'High',
                                        },
                                    ]}
                                />}
                        </div>
                        {/* <div className='job-cols'>
                            <label className='mb-2'>Next review date</label>
                            {clientDetails?.next_review ? <h5>{moment(clientDetails?.next_review).format('lll')}</h5> :
                                <DatePicker style={{ width: 250 }} onChange={(date) => setFormValues({ ...formValues, 'next_review': date })} className='custom-datepicker' />}
                        </div> */}
                        <div className='job-cols'>
                            <label className='mb-2'>Contact person 1</label>
                            {clientDetails?.contact_person1 ? <h5>{clientDetails?.contact_person1}</h5> :
                                <input type="text" className='custom-input' placeholder='Enter Contact person 1' onChange={(e) => setFormValues({ ...formValues, 'contact_person1': e.target.value })} />}
                        </div>
                        <div className='job-cols'>
                            <label className='mb-2'>Contact person 2</label>
                            {clientDetails?.contact_person2 ? <h5>{clientDetails?.contact_person2}</h5> :
                                <input type="text" className='custom-input' placeholder='Enter Contact person 1' onChange={(e) => setFormValues({ ...formValues, 'contact_person2': e.target.value })} />}
                        </div>
                        <div className='job-cols'>
                            <label className='mb-2'>Contact person 3</label>
                            {clientDetails?.contact_person3 ? <h5>{clientDetails?.contact_person3}</h5> :
                                <input type="text" className='custom-input' placeholder='Enter Contact person 1' onChange={(e) => setFormValues({ ...formValues, 'contact_person3': e.target.value })} />}
                        </div>
                        <div className='job-cols'>
                            <label className='mb-2'>Comment</label>
                            {clientDetails?.comments ? <h5>{clientDetails?.comments}</h5> :
                                <textarea name="" id="" cols="50" rows="5" placeholder='Write your comment here...' onChange={(e) => setFormValues({ ...formValues, 'comments': e.target.value })} className='custom-input'></textarea>}
                        </div>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <button className='secondary-btn me-2'><Link to={`/client`} style={{ color: "#fff" }}>Back</Link></button>
                        <button className='primary-btn ms-2' onClick={() => submitForm()}>Submit</button>
                    </div>
                </div>
                <div className={`tab-panel ${selectedTab == 2 ? 'active' : ''}`}>
                    <Table columns={columns} dataSource={tableData} className='custom-table  custom-table1' pagination={false} loading={false} />
                    <Modal open={previewOpen} centered footer={null} onCancel={handleCancel}>
                        {previewImage ? (
                            <iframe
                                title="PDF Preview"
                                width="100%"
                                height="600px"
                                src={`data:application/pdf;base64,${previewImage}`}
                            />
                        ) : (
                            ""
                        )}
                    </Modal>
                    <Modal open={uploadOpen} centered title={'Upload Document'} footer={null} onCancel={handleUploadCancel}>
                        <div className={`custom-dragger p-0 w-100 h-250`}>
                            <div className='custom-btn flex-column' onClick={() => uploader.current.click()}>
                                <input
                                    type="file"
                                    accept="pdf/*"
                                    name=""
                                    id=""
                                    onChange={handleFileChange}
                                    ref={uploader}
                                    className='d-none' />
                                <p>Click to upload file</p>
                            </div>
                            {selectedFile && (
                                <div>
                                    <p>Uploaded File: {selectedFile.name}</p>
                                    {/* You can include additional information about the file if needed */}
                                    {/* <p>File size: {selectedFile.size} bytes</p> */}
                                </div>
                            )}
                        </div>
                        <div>
                            <label className='mb-2 mt-3' style={{ fontWeight: 'bold', width: '100%' }}>Expiry Date</label>
                        </div>
                        <DatePicker style={{ width: 250 }} onChange={(date) => setExpiryDate(date)} className='custom-datepicker' />
                        <div >
                            <label className='mb-2 mt-3' style={{ fontWeight: 'bold' }}>Document Name</label>
                            <input type="text" className='custom-input' style={{ fontWeight: 'bold', width: '100%' }} placeholder='Enter Document Name' onChange={(e) => setDocumentName(e.target.value)} />
                        </div>
                        {<div className='d-flex justify-content-center mt-4'>



                            {<Button onClick={() => submitImages()} className='primary-btn ms-2' type="primary"  loading={isUploade} >Submit</Button>}
                        </div>}
                    </Modal>
                    <Modal open={mutliple} centered title={mutipleDocumentName} footer={null} onCancel={handleMutipleCancel} width={'auto'}>
                        <button className='primary-btn mr-auto mb-4' onClick={() => setUploadOpen(true)}><i className="fas fa-plus-circle"></i> Add Document</button>
                        <Table rowSelection={{
                            type: "checkbox",
                            ...rowSelection,
                        }} columns={documentcolumns} dataSource={popupData} className='custom-table' pagination={false} loading={false} />
                        <div className='d-flex mt-4'>
                            <button className='primary-btn mr-auto me-3' onClick={() => downloadSelected('ALL')}><i className="fas fa-plus-circle"></i>Download All</button>
                            <button className='primary-btn mr-auto ms-3' onClick={() => downloadSelected('SELECTED')}><i className="fas fa-plus-circle"></i>Download Selected</button>
                        </div>
                    </Modal>
                    <div className='d-flex mt-4'>
                        <button className='primary-btn mr-auto me-3' onClick={() => individualData(clientDetails, 'downloadall')}><i className="fas fa-plus-circle"></i>Download All</button>
                    </div>
                </div>
            </div>
        </section >
    )
}
