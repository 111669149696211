import React, { useEffect, useState } from "react";
import { Select, Table, Switch, Cascader, Checkbox, Row, Col } from "antd";

import AccessControlRepository from '../../repository/access-control';
import moment from 'moment';

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export default function AccessControl() {

  const { Option } = Select;

  const [errors, setErrors] = useState({});

  const [groupArray, setGroupArray] = useState([])
  const [tableData, setTableData] = useState([])
  const [selectedId, setSelectedId] = useState('');
  const [clientDetails, setClientDetails] = useState(false);
  const [editableFields, setEditableFields] = useState(false);
  const [issubmit, setIssubmit] = useState(false);
  const [isback, setIsback] = useState(false);

  const [documents, setDocuments] = useState(false);
  const [mandatoryStatus, setMandatoryStatus] = useState(false);
  const [isupload, setIsupload] = useState(false);
  const [isdelete, setIsdelete] = useState(false);
  const [isdownload, setIsdownload] = useState(false);
  const [groupId, setGroupId] = useState('');

  useEffect(() => {

    (async () => {
      let ctr = {}
      const data = await AccessControlRepository.getGroup(ctr);
      if (data && data?.data?.data?.length) {
        setGroupArray(data?.data.data)
        groupOnChange(data?.data.data[0]._id)
      }
    }
    )()
  }, []);

  const onChangeHandler = (setIdentifierState, event) => {
    let errorObj = { ...errors }
    errorObj[event?.target?.name] = ''
    setIdentifierState(event?.target?.value)
    setErrors(errorObj)
  }

  let columns = [
    {
      title: "Granted Screens",
      dataIndex: "grantedScreens",
    },
    {
      title: "Access",
      dataIndex: "access",

    },

    {
      title: "Granted By",
      dataIndex: "grantedBy",
    },
    {
      title: "Granted Date",
      dataIndex: "grantedDate",
    },
  ];

  const groupOnChange = async (id) => {
    setGroupId(id)
    updateTableData(id)
  }
  const onStatusChanged = async (id, field, status) => {
    await AccessControlRepository.updateStatus({ id, field, status })
    updateTableData(id)
  }
  const updateOnClick = async () => {

    let clientObj = {
      details: clientDetails,
      children: {
        editable_fields: editableFields,
        submit: issubmit,
        back: isback
      }
    }

    let documentsObj = {
      documents: documents,
      children: {
        mandatory_status: mandatoryStatus,
        upload: isupload,
        delete: isdelete,
        download: isdownload
      }

    }
    let obj = {
      "client": clientObj,
      "documents": documentsObj
    }
    let update = await AccessControlRepository.editGroup(selectedId, obj);
    updateTableData(selectedId)
  }

  const updateTableData = async (id) => {
    setSelectedId(id)
    let ctr = {}
    ctr.id = id
    const groupData = await AccessControlRepository.getGroup(ctr);
    let data = [];
    if (groupData.status_code == 200) {
      let finded = groupData.data.data

      await asyncForEach(finded, async (oneDoc) => {
        if (oneDoc.features.dashboard !== null) {
          let val = {
            "access": (<Switch checked={oneDoc.features.dashboard ? true : false}
              onChange={() => onStatusChanged(id, 'features.dashboard', oneDoc.features.dashboard == true ? false : true)}

            />),
            "grantedScreens": 'Dashboard'
          }
          data.push(val)
        }
        if (oneDoc.features.ticket_screens !== null) {
          let val = {
            "access": (<Switch checked={oneDoc.features.ticket_screens ? true : false}
              onChange={() => onStatusChanged(id, 'features.ticket_screens', oneDoc.features.ticket_screens == true ? false : true)}

            />),
            "grantedScreens": 'Ticket Screens'
          }
          data.push(val)
        }
        if (oneDoc.features.settings !== null) {
          let val = {
            "access": (<Switch checked={oneDoc.features.settings ? true : false}
              onChange={() => onStatusChanged(id, 'features.settings', oneDoc.features.settings == true ? false : true)}

            />),
            "grantedScreens": 'Settings'
          }
          data.push(val)
        }
        if (oneDoc.features.jobs !== null) {
          let val = {
            "access": (<Switch checked={oneDoc.features.jobs ? true : false}
              onChange={() => onStatusChanged(id, 'features.jobs', oneDoc.features.jobs == true ? false : true)}

            />),
            "grantedScreens": 'Jobs'
          }
          data.push(val)
        }
        if (oneDoc.features.client !== null) {
          let val = {
            "access": (<Switch checked={oneDoc.features.client ? true : false}
              onChange={() => onStatusChanged(id, 'features.client', oneDoc.features.client == true ? false : true)}

            />),
            "grantedScreens": 'Client'
          }
          data.push(val)
        }
        if (oneDoc.features.seriodical_reviews !== null) {
          let val = {
            "access": (<Switch checked={oneDoc.features.seriodical_reviews ? true : false}
              onChange={() => onStatusChanged(id, 'features.seriodical_reviews', oneDoc.features.seriodical_reviews == true ? false : true)}
            />),
            "grantedScreens": 'Periodical Reviews',
          }
          data.push(val)
        }
        if (oneDoc.features.access_ccontrol !== null) {
          let val = {
            "access": (<Switch checked={oneDoc.features.access_ccontrol ? true : false}
              onChange={() => onStatusChanged(id, 'features.access_ccontrol', oneDoc.features.access_ccontrol == true ? false : true)}

            />),
            "grantedScreens": 'Access control'
          }
          data.push(val)
        }

        if (oneDoc.client.details !== null) {
          setClientDetails(oneDoc.client.details)
        }
        if (oneDoc.client.children.editable_fields !== null) {
          setEditableFields(oneDoc.client.children.editable_fields)
        }
        if (oneDoc.client.children.submit !== null) {
          setIssubmit(oneDoc.client.children.submit)
        }
        if (oneDoc.client.children.back !== null) {
          setIsback(oneDoc.client.children.back)
        }
        if (oneDoc.documents.documents !== null) {
          setDocuments(oneDoc.documents.documents)
        }
        if (oneDoc.documents.children.mandatory_status !== null) {
          setMandatoryStatus(oneDoc.documents.children.mandatory_status)
        }
        if (oneDoc.documents.children.upload !== null) {
          setIsupload(oneDoc.documents.children.upload)
        }
        if (oneDoc.documents.children.delete !== null) {
          setIsdelete(oneDoc.documents.children.delete)
        }
        if (oneDoc.documents.children.download !== null) {
          setIsdownload(oneDoc.documents.children.download)
        }

        await asyncForEach(data, async (oneDoc) => {
          oneDoc["grantedBy"] = groupData.data.data.map(m => m.createdby)
          oneDoc["grantedDate"] = groupData.data.data.map(m => moment(m.updatedAt).format('DD-MMM-YYYY'))
        })
      });
    }
    setTableData(data)
  }

  return (
    <div className="row">
      <div className="col-md-8">
        <div className="border-box">
          <div className="d-flex align-items-center mb-3">
            <label htmlFor="" className="me-2">
              Select Group:
            </label>
            <Select
              style={{
                width: 160,
              }}
              onChange={groupOnChange}
              placeholder="Select any"
              value={groupId ? groupId : null}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {groupArray.map(ga => {
                return (
                  <Option value={ga._id} key={ga._id}>{ga.title}</Option>
                )
              })}
            </Select>
          </div>
          <Table
            columns={columns}
            dataSource={tableData}
            className="custom-table"
            pagination={false}
            bordered
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="border-box">
          <h6 className="mb-4">Sub Screens and Functions</h6>
          <div className="col-lg-12" key={1} style={{ padding: 10 }}>
            <div className="row no-gutters">
              <div className="col-lg-12">
                <Checkbox
                  checked={clientDetails}
                  value={!clientDetails}
                  onChange={onChangeHandler.bind(null, setClientDetails)}
                >Client Details</Checkbox>
              </div>
              <br />
              {clientDetails ?
                <div className="d-flex flex-column ms-5">
                  <Checkbox
                    checked={editableFields}
                    value={!editableFields}
                    onChange={onChangeHandler.bind(null, setEditableFields)}
                  >Editable Fields</Checkbox>
                  <Checkbox
                    checked={issubmit}
                    value={!issubmit}
                    onChange={onChangeHandler.bind(null, setIssubmit)}
                  >Submit</Checkbox>
                  <Checkbox
                    value={!isback}
                    checked={isback}
                    onChange={onChangeHandler.bind(null, setIsback)}
                  >Back</Checkbox>
                </div>
                : <></>}
            </div>
          </div>
          <div className="col-lg-12" key={1} style={{ padding: 10 }}>
            <div className="row no-gutters">
              <div className="col-lg-12">
                <Checkbox
                  checked={documents}
                  value={!documents}
                  onChange={onChangeHandler.bind(null, setDocuments)}
                >Documents</Checkbox>
              </div>
              <br />
              {documents ?
                <div className="d-flex flex-column ms-5">

                  <Checkbox
                    checked={mandatoryStatus}
                    value={!mandatoryStatus}
                    onChange={onChangeHandler.bind(null, setMandatoryStatus)}
                  >Mandatory Status</Checkbox>
                  <Checkbox
                    checked={isupload}
                    value={!isupload}
                    onChange={onChangeHandler.bind(null, setIsupload)}
                  >Upload</Checkbox>
                  <Checkbox
                    checked={isdelete}
                    value={!isdelete}
                    onChange={onChangeHandler.bind(null, setIsdelete)}
                  >Delete</Checkbox>
                  <Checkbox
                    checked={isdownload}
                    value={!isdownload}
                    onChange={onChangeHandler.bind(null, setIsdownload)}
                  >Download</Checkbox>
                </div>
                : <></>}
            </div>
          </div>

          <button onClick={updateOnClick} className="primary-btn" type="button">
            Enable Access
          </button>

        </div>
      </div>
    </div>
  );
}
