import React, { useEffect, useState } from 'react'
import { Table, DatePicker, Tag, Modal, Tooltip, notification, Select } from 'antd';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx';

import ticketRep from '../repository/ticketRep';
import authRep from '../repository/authRep';
import { checkLogged } from '../store/auth/authSlice';
import { sendNotify } from '../helper/notification';

const { RangePicker } = DatePicker;

export default function Tickets() {

  var emailValidate = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { RangePicker } = DatePicker;

  // Note: Only add required fields to this object.
  let formShama = {
    client_name: '',
    point_of_contact: '',
    poc_mail: '',
    poc_mobile: '',
    client_category: '',
    partner: ''
  }

  const [ticketModal, setTicketModal] = useState(false);
  const [fromValues, setFromValues] = useState(formShama);
  const [errorObj, setErrorObj] = useState(formShama);
  const [tableData, setTableData] = useState([])
  const [jobModal, setjobModal] = useState(false);
  const [entityData, setEntityData] = useState({})
  const [status, setStatus] = useState('');
  const [selectedTicket, setSelectedTicket] = useState("");
  const [trackcode, setTrackcode] = useState('');
  const [clientArray, setClientArrays] = useState([])
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (id) => {
    setSelectedTicket(id)
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    deleteTicket(selectedTicket);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    dispatch(checkLogged())
    getTickets();
    getProfile();
  }, [])

  const getTickets = () => {
    ticketRep.getTickets().then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.message)
        } else {
          if (response && response?.data.length > 0) {
            let obj = response?.data.map((data, i) => {
              return {
                key: i,
                id: data.client_id,
                name: data.client_name,
                point_of_contact: data.point_of_contact,
                createdBy: data?.createdBy?.name,
                createdAt: moment(data.createdAt).format('ll'),
                status: [data.status],
                ticketId: data._id
              }
            })
            setTableData(obj)
           
          }
        }
      }
    })
      .catch(error => (sendNotify('error', JSON.stringify(error))));
  }

  const columns = [
    {
      title: 'Client ID',
      dataIndex: 'id',
    },
    {
      title: 'Client Name',
      dataIndex: 'name',
    },
    {
      title: 'Point of Contact (POC)',
      dataIndex: 'point_of_contact',
      render: cell => <Tooltip title={cell}>{cell}</Tooltip>
    },
    {
      title: 'Ticket Created By',
      dataIndex: 'createdBy',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <>
          {status.map((s) => {
            let color = '';
            switch (s) {
              case 'Pending':
                color = 'gold'
                break;
              case 'Acknowledged':
                color = 'green'
                break;
            }
            return (
              <Tag color={color} key={s}>{s}</Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, { id, ticketId }) => (
        <div className="d-flex" key={id}>
          {/* <button className='action-btn' onClick={() => getId(id)}><i className="far fa-eye"></i></button> */}
          <button className='action-btn' onClick={() => getId(id)}><i className="far fa-edit"></i></button>
          <div className='action-btn' style={{ color: "red" }} onClick={() => showModal(ticketId)}><i className="far fa-trash"></i></div>
        </div>
      ),
    },
  ];

  const deleteTicket = async (id) => {
    ticketRep.deleteTicketById({ ticket_id: id }).then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.message)
        } else {
          if (response && response?.data._id) {
            getTickets();
            sendNotify('success', response.message)
          }
        }
      }
    })
      .catch(error => (sendNotify('error', JSON.stringify(error))));
  }

  const getInputValue = (field, value) => {
    let values = { ...fromValues }
    let errors = { ...errorObj }
    values[field] = value
    setFromValues(values)
    if (value) {
      errors[field] = ''
      if (field == 'poc_mail') if (!emailValidate.test(value)) errors['poc_mail'] = 'Enter valid poc_mail address'
      setErrorObj(errors)
    } else {
      errors[field] = `${field} was required`
      setErrorObj(errors)
    }
  }

  function checkEmpty() {
    let values = {
      ...fromValues,
    }
    let errors = { ...errorObj }
    let emptyArr = []
    for (var key in values) {
      if (values[key] === "" || errors[key] !== '') {
        emptyArr.push(key)
      }
    }
    return emptyArr;
  }
  function formValidation() {
    let values = {
      ...fromValues,
    }
    let errors = { ...errorObj }
    let empty = checkEmpty()
    if (empty?.length > 0) {
      empty.forEach((field) => {
        if (values[field]) {
          if (field == 'poc_mail') if (!emailValidate.test(values['poc_mail'])) errors['poc_mail'] = 'Enter valid poc_mail address'
        } else {
          errors[field] = `${field} was required`
        }
      })
    } else {
      return true
    }
    setErrorObj(errors)
    return false
  }

  const getProfile = () => {
    authRep.getprofile().then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.message);
        } else {
          setTrackcode(response.data[0].company_key)
        }
      }
    });
  }

  const submitForm = () => {
    const uniqueID = uuidv4()
    let data = localStorage.getItem('session')
    let session = JSON.parse(data)
    let userId = session.id

    let payload = {
      ...fromValues,
      createdBy: userId,
      client_id: trackcode + uniqueID.substring(0, 6),
      status: "Pending",
    }
    if (formValidation()) {
      ticketRep.createticket(payload).then(response => {
        if (response) {
          if (response.error) {
            sendNotify('error', response.error?.response?.data?.message)
          } else {
            sendNotify('success', response?.message)
            getTickets();
          }
        }
      })
      setFromValues(formShama)
      setErrorObj(formShama)
      setTicketModal(false)
    } else {
      notification['error']({
        message: "Some form field(s) are missing!",
      });
    }
  }

  const cancelHandle = () => {
    setFromValues(formShama)
    setErrorObj(formShama)
    setTicketModal(false)
    setjobModal(false)
  }
  const submitFormstatus = () => {
    let payload = {
      id: entityData,
      status: status,
      mode: "update"
    }
    ticketRep.createticket(payload).then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.data?.message)
        } else {
          sendNotify('success', response?.message)
          getTickets();
        }
      }
    })
    setjobModal(false)
  }
  const getId = (data) => {
    setjobModal(true)
    setEntityData(data);
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const idArray = selectedRows.map(row => row.id);
      setClientArrays(idArray)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };
  const bulkAssign = () => {
    let payload = {
      "id": clientArray,
      "mode": "update",
      "status": "Acknowledged"
    }
    ticketRep.bulkAcknowledgeTicket(payload).then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.data?.message)
        } else {
          sendNotify('success', response?.message)
          getTickets();
        }
      }
    })
  }
  const dataExtraction = () => {
    let payload = {
      "startDate": startDate,
      "endDate": endDate,
      "mode": "ticket"
    }
    ticketRep.ticketExtraction(payload).then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.data?.message)
        } else {
          sendNotify('success', response?.message)
          if (response?.data) {
            let obj = response?.data?.map((data, i) => {
              return {
                id: data.client_id,
                name: data.client_name,
                point_of_contact: data.point_of_contact,
                createdBy: data?.createdBy?.name,
                createdAt: moment(data.createdAt).format('ll'),
                status: [data.status],
                ticketId: data._id
              }
            })
            let objs = response?.data?.map((data, i) => {
              return {
                Client_Id: data.client_id,
                Client_Name: data.client_name,
                Point_of_contact: data.point_of_contact,
                Created_By: data?.createdBy?.name,
                Created_At: moment(data.createdAt).format('ll'),
                Ctatus: data.status,
                TicketId: data._id
              }
            })
            exportToExcel(objs, 'tickets.xlsx');
            setTableData(obj)
          }
        }
      }
    })
  }
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };
  function exportToExcel(data, fileName) {
    const workbook = XLSX.utils.book_new();

    // Create a worksheet and add data to it
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Convert the workbook to a data URL
    const excelDataUrl = XLSX.write(workbook, { bookType: 'xlsx', type: 'base64' });

    // Create a data URI for the Excel file
    const dataUri = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${excelDataUrl}`;

    // Create an anchor element for downloading
    const a = document.createElement('a');
    a.href = dataUri;
    a.download = fileName;

    // Trigger a click event to initiate the download
    a.click();
  }

  return (
    <section className='mt-md-3'>
      <div className="page-header">
        <h1>Ticket List</h1>
        <button className='primary-btn' onClick={() => setTicketModal(true)}><i className="fas fa-plus-circle"></i> Add New Ticket</button>
      </div>
      <div className="page-header flex-md-row flex-column">
        <button className='primary-btn' onClick={() => bulkAssign()}><i class="fas fa-file-check"></i>Bulk Acknowledge</button>
        <div className='d-flex'>
          <RangePicker onCalendarChange={getTickets} onChange={handleDateRangeChange} className='custom-datepicker' />
          <button className='primary-btn ms-2' onClick={() => dataExtraction()}><i className="far fa-file-export me-0 me-md-1"></i> <span className='d-none d-md-inline'>Extract</span></button>
        </div>
        {/* <div className="custom-search my-1">
          <input type="text" placeholder='Search by name/ job' />
          <button><i className="far fa-search"></i></button>
        </div> */}
      </div>
      <Table rowSelection={{
        type: "checkbox",
        ...rowSelection,
      }}
        columns={columns} dataSource={tableData} pagination={{ className: 'custom-pagination', defaultCurrent: 1, total: 50 }} className='custom-table' loading={false} />


      <Modal title="Add Ticket" centered width={600} className='custom-modal' open={ticketModal} onOk={submitForm}
        onCancel={cancelHandle} okText={'Create'}>
        {/* <div className="input-box">
          <label htmlFor="clientId">Client ID</label>
          <div className='input-field'>
            <input type="text" name='clientId' id='clientId' value={uniqueID.substring(0, 8)} disabled />
            <Tooltip title="Auto Generated"><button className='input-after'><i className="fal fa-info-circle"></i></button></Tooltip>
          </div>
        </div> */}
        <div className={`input-box req ${errorObj['client_name'] ? 'input-error' : ''}`}>
          <label htmlFor="clientName">Client Name <span className='err-meg'>{errorObj['client_name']}</span></label>
          <input type="text" name='clientName' id='clientName' placeholder='Enter client name' value={fromValues['client_name']} onChange={(e) => getInputValue('client_name', e.target.value)} />
        </div>
        <div className={`input-box req ${errorObj['point_of_contact'] ? 'input-error' : ''}`}>
          <label htmlFor="point_of_contact">Point of Contact (POC) <span className='err-meg'>{errorObj['point_of_contact']}</span></label>
          <input type="text" name='point_of_contact' id='point_of_contact' placeholder='Enter point of contact' value={fromValues['point_of_contact']} onChange={(e) => getInputValue('point_of_contact', e.target.value)} />
        </div>
        <div className={`input-box req ${errorObj['poc_mail'] ? 'input-error' : ''}`}>
          <label htmlFor="pocEmail">POC Email <span className='err-meg'>{errorObj['poc_mail']}</span></label>
          <div className='input-field'>
            <input type="text" name='pocEmail' id='pocEmail' placeholder='Enter point_of_contact poc_mail' value={fromValues['poc_mail']} onChange={(e) => getInputValue('poc_mail', e.target.value)} />
            <Tooltip title="Give any message"><button className='input-after'><i className="fal fa-info-circle"></i></button></Tooltip>
          </div>
        </div>
        <div className="input-box">
          <label htmlFor="pocMobile">POC Mobile</label>
          <div className='input-field'>
            <input type="text" name='pocMobile' id='pocMobile' placeholder='Enter point_of_contact poc_mobile' value={fromValues['poc_mobile']} onChange={(e) => getInputValue('poc_mobile', e.target.value)} />
            <Tooltip title="Give any message"><button className='input-after'><i className="fal fa-info-circle"></i></button></Tooltip>
          </div>
        </div>
        <div className={`input-box req ${errorObj['client_category'] ? 'input-error' : ''}`}>
          <label htmlFor="clientCategory">Client Category <span className='err-meg'>{errorObj['client_category']}</span></label>
          <input type="text" name='clientCategory' id='clientCategory' placeholder='Enter client client_category' value={fromValues['client_category']} onChange={(e) => getInputValue('client_category', e.target.value)} />
        </div>
        <div className={`input-box req ${errorObj['partner'] ? 'input-error' : ''}`}>
          <label htmlFor="partner">Partner <span className='err-meg'>{errorObj['partner']}</span></label>
          <input type="text" name='partner' id='partner' placeholder='Enter client partner' value={fromValues['partner']} onChange={(e) => getInputValue('partner', e.target.value)} />
        </div>
      </Modal>

      <Modal title="Update Ticket" centered width={600} className='custom-modal' open={jobModal} onOk={submitFormstatus}
        onCancel={cancelHandle} okText={'Update'}>
        <div className={`input-box`}>
          <label htmlFor="risk_level">Status</label>
          <Select
            className='custom-select w-100'
            onChange={(value) => setStatus(value)}
            placeholder="Status"
            options={[
              {
                value: 'Acknowledged',
                label: 'Acknowledged',
              },
              {
                value: 'Pending',
                label: 'Pending',
              },
            ]}
          />
        </div>
      </Modal>
      <Modal title="Confirmation Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Are you sure</p>
      </Modal>
    </section>
  )
}
