import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLogged: false,
  authToken: ''
}

export const authSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    checkLogged: (state) => {
      let token = localStorage.getItem('nl_auth')
      state.isLogged = token ? true : false
      state.authToken = token
    },
  },
})

// Action creators are generated for each case reducer function
export const { checkLogged } = authSlice.actions

export default authSlice.reducer