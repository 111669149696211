import axios from 'axios';

export const apiUrl = process.env.REACT_APP_API_URL;
// export const apiUrl = "http://localhost:5000";

let customHeaders = {
  Accept: "application/json",
};

let token = ''


let local = typeof window !== 'undefined' ? localStorage : null;
if (local && local.nl_auth) {
    customHeaders['authorization'] = `Bearer ${local.nl_auth}`;
}

export default axios.create({
  headers: customHeaders,
});