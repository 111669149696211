import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'

import Logo from '../../assets/img/logo.png'

import { NavLink } from "react-router-dom";

import routes from '../../helper/routes';

import { sidebarToggle } from '../../store/sidebar/sidebarSlice';
import AccessControlRepository from '../../repository/access-control';

import Footer from '../base/Footer'

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export default function Sidebar() {
  const dispatch = useDispatch()

  const [newRoutes, setNewRoutes] = useState([]);
  const sidebarStatus = useSelector((state) => state.sidebar.sidebarStatus)

  useEffect(() => {

    (async () => {
      let local = localStorage.getItem('session')
      let session = JSON.parse(local)

      let ctr = {}
      ctr.id = session?.role
      const groupData = await AccessControlRepository.getGroup(ctr);

      if (groupData.status_code == 200) {
        let finded = groupData.data.data
        let sidebar = routes.filter(r => r.showInMenu)

        await asyncForEach(sidebar, async (one_Doc) => {

          if (one_Doc.name == 'Dashboard') {

            await asyncForEach(finded, async (oneDoc) => {
              if (oneDoc.features.dashboard !== null) {
                one_Doc.permissions = oneDoc.features.dashboard
              }
            })
          }
          if (one_Doc.name == 'Tickets') {
            await asyncForEach(finded, async (oneDoc) => {
              if (oneDoc.features.ticket_screens !== null) {
                one_Doc.permissions = oneDoc.features.ticket_screens
              }
            })
          }
          if (one_Doc.name == 'Settings') {
            await asyncForEach(finded, async (oneDoc) => {
              if (oneDoc.features.settings !== null) {
                one_Doc.permissions = oneDoc.features.settings
              }
            })
          }
          if (one_Doc.name == 'Jobs') {
            await asyncForEach(finded, async (oneDoc) => {
              if (oneDoc.features.jobs !== null) {
                one_Doc.permissions = oneDoc.features.jobs
              }
            })
          }
          if (one_Doc.name == 'Clients') {
            await asyncForEach(finded, async (oneDoc) => {
              if (oneDoc.features.client !== null) {
                one_Doc.permissions = oneDoc.features.client
              }
            })
          }
          if (one_Doc.name == 'Periodic') {
            await asyncForEach(finded, async (oneDoc) => {
              if (oneDoc.features.seriodical_reviews !== null) {
                one_Doc.permissions = oneDoc.features.seriodical_reviews
              }
            })
          }
          if (one_Doc.name == 'Access Control') {
            await asyncForEach(finded, async (oneDoc) => {
              if (oneDoc.features.access_ccontrol !== null) {
                one_Doc.permissions = oneDoc.features.access_ccontrol
              }
            })
          }
        })
        setNewRoutes(sidebar)
      }
    }
    )()
  }, []);

  return (
    <div className={`sidebar ${sidebarStatus ? 'sidebar-collapse' : ''}`}>
      <img src={Logo} className='logo' alt="NorthLark" />
      <ul>
        {newRoutes.filter(r => r.showInMenu && r.permissions == true).map(route => {
          return (
            <li key={route.path}>
              <NavLink to={route.path}>
                <i className={`far ${route.icon}`}></i>
                <span>{route.name}</span>
              </NavLink>
            </li>
          )
        })}
      </ul>
      <button onClick={() => dispatch(sidebarToggle())} className={`toggle-btn ${sidebarStatus ? 'rotate' : ''}`}>
        <i className="fad fa-chevron-double-left"></i>
      </button>
      <Footer />
    </div>
  )
}
