// Auth
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";

//Private
import Dashbaord from "../pages/dashboard";
import Tickets from "../pages/tickets";
import Jobs from "../pages/jobs";
import JobStatus from "../pages/job-status";
import Workflow from "../pages/workflow";
import Clients from "../pages/clients";
import EditClient from "../pages/clients/edit";
import Periodic from "../pages/periodic";
import Settings from "../pages/settings";
import AccessControl from "../pages/access-control";

import NotFound from "../pages/not-found";

const routes = [
  {
    name: "Login",
    path: "/",
    icon: "",
    component: <Login />,
    type: "public",
    permissions: [],
    auth: [],
    showInMenu: false,
  },
  {
    name: "Login",
    path: "/login",
    icon: "",
    component: <Login />,
    type: "public",
    permissions: [],
    auth: [],
    showInMenu: false,
  },
  {
    name: "Register",
    path: "/register",
    icon: "",
    component: <Register />,
    type: "public",
    permissions: [],
    auth: [],
    showInMenu: false,
  },
  {
    name: "Forgot Password",
    path: "/forgot-password",
    icon: "",
    component: <Login />,
    type: "public",
    permissions: [],
    auth: [],
    showInMenu: false,
  },
  {
    name: "Two Factor",
    path: "/two-factor",
    icon: "",
    component: <Login />,
    type: "public",
    permissions: [],
    auth: [],
    showInMenu: false,
  },
  {
    name: "Reset Password",
    path: "/reset-password",
    icon: "",
    component: <Login />,
    type: "public",
    permissions: [],
    auth: [],
    showInMenu: false,
  },
  {
    name: "Dashboard",
    path: "dashboard",
    icon: "fa-chart-bar",
    component: <Dashbaord />,
    type: "private",
    permissions: [],
    auth: [],
    showInMenu: true,
  },
  // {
  //   name: "Workflow",
  //   path: "workflow",
  //   icon: "fa-network-wired",
  //   component: <Workflow />,
  //   type: 'private',
  //   permissions: [],
  //   auth: [],
  //   showInMenu: true,
  // },
  {
    name: "Tickets",
    path: "tickets",
    icon: "fa-ticket-alt",
    component: <Tickets />,
    type: "private",
    permissions: [],
    auth: [],
    showInMenu: true,
  },
  {
    name: "Settings",
    path: "settings",
    icon: "fa-cog",
    component: <Settings />,
    type: "private",
    permissions: [],
    auth: [],
    showInMenu: true,
  },

  {
    name: "Jobs",
    path: "jobs",
    icon: "fa-briefcase",
    component: <Jobs />,
    type: "private",
    permissions: [],
    auth: [],
    showInMenu: true,
  },
  {
    name: "Job",
    path: "job/:id",
    icon: "fa-briefcase",
    component: <JobStatus />,
    type: "private",
    permissions: [],
    auth: [],
    showInMenu: false,
  },
  {
    name: "Clients",
    path: "clients",
    icon: "fa-clipboard-user",
    component: <Clients />,
    type: "private",
    permissions: [],
    auth: [],
    showInMenu: true,
  },
  {
    name: "Clients",
    path: "client/:id",
    icon: "fa-clipboard-user",
    component: <EditClient />,
    type: "private",
    permissions: [],
    auth: [],
    showInMenu: false,
  },
  {
    name: "Periodic",
    path: "periodic",
    icon: "fa-table",
    component: <Periodic />,
    type: "private",
    permissions: [],
    auth: [],
    showInMenu: true,
  },
  {
    name: "Access Control",
    path: "access-control",
    icon: "fa-user-shield",
    component: <AccessControl />,
    type: "private",
    permissions: [],
    auth: [],
    showInMenu: true,
  },
  {
    name: "Error 404",
    path: "*",
    icon: "",
    component: <NotFound />,
    type: "public",
    permissions: [],
    auth: [],
    showInMenu: false,
  },
];

export default routes;
