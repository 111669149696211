import React, { useEffect, useState } from 'react'
import { Table, Tag, Modal, DatePicker, Select } from 'antd';

import moment from 'moment';
import * as XLSX from 'xlsx';

import jobRep from '../repository/jobRep';
import authRep from '../repository/authRep';
import { sendNotify } from '../helper/notification';
import { Link } from 'react-router-dom';

export default function Jobs() {
  const { RangePicker } = DatePicker;
  const [ticketModal, setTicketModal] = useState(false);
  const [tableData, setTableData] = useState([])
  const [fromValues, setFromValues] = useState({});
  const [selectedJob, setSelectedJob] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [clientArray, setClientArrays] = useState([])
  const [analystList, setAnalystList] = useState()
  const [isLoading, setIsLoading] = useState(false);

  const showModal = (id) => {
    setSelectedJob(id)
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    deleteJob(selectedJob);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  let columns = [
    {
      title: 'Job  ID',
      dataIndex: 'id',
    },
    {
      title: 'Client Name',
      dataIndex: 'type',
    },
    {
      title: 'Created By',
      dataIndex: 'createdby',
    },
    {
      title: 'Created At',
      dataIndex: 'createdat',
    },
    {
      title: 'Assigned To',
      dataIndex: 'assign',
    },
    {
      title: 'Recent changed By',
      dataIndex: 'nextdate',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <>
          {status.map((s) => {
            let color = '';
            switch (s) {
              case 'Acknowledged':
                color = 'gold'
                break;
              case 'RFI':
                color = 'blue'
                break;
              case 'Completed':
                color = 'green'
                break;
              case 'OnHold':
                color = 'red'
                break;
            }
            return (
              <Tag color={color} key={s}>{s}</Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions'
    }
  ];

  useEffect(() => {
    getjobs();
    getAnalyst();
  }, [])

  // const downloadFiles = (id) => {
  //   jobRep.getFileFromS3(id).then(response => {
  //     if (response.error) {
  //       sendNotify('error', response.error?.response?.message)
  //     } else {
  //       downloadBase64File(response.data, 'image/png', 'file.png');
  //     }
  //   })
  //     .catch(error => (sendNotify('error', JSON.stringify(error))));
  // }

  // const downloadBase64File = (base64Data, contentType, fileName) => {
  //   const linkSource = `data:${contentType};base64,${base64Data}`;
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = linkSource;
  //   downloadLink.download = fileName;
  //   downloadLink.click();
  // }

  const deleteJob = async (jobData) => {
    jobRep.deleteJobById({ ticket_id: jobData.ticketId }).then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.message)
        } else {
          if (response && response?.data._id) {
            getjobs();
            sendNotify('success', response.message)
          }
        }
      }
    })
      .catch(error => (sendNotify('error', JSON.stringify(error))));

  }
  const getjobs = () => {
    jobRep.getjobs().then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.message)
        } else {
          if (response && response?.data.length > 0) {
            let obj = response?.data.map((data, i) => {
              return {
                key: i,
                tableId: data?.ticketData?._id,
                id: data?.ticketData?.client_id,
                type: data?.ticketData?.client_name,
                createdby: data?.userData?.name,
                createdat: moment(data?.ticketData?.createdAt).format('ll'),
                nextdate: data?.recentChange ? data?.recentChange?.name : "--",
                assign: data?.assinedTo ? data?.assinedTo?.name : "--",
                status: [data?.ticketData?.status],
                actions: (
                  <div className="d-flex" >
                    <Link to={`/app/job/${data.ticketData.client_id}`} className='action-btn'><i className="far fa-eye"></i></Link>
                    <div className='action-btn' style={{ color: "red" }} onClick={() => showModal(data)}><i className="far fa-trash"></i></div>
                  </div>
                ),
              }
            })
            setTableData(obj)
          }
        }
      }
    })
      .catch(error => (sendNotify('error', JSON.stringify(error))));
  }
  const dataExtraction = () => {
    let payload = {
      "startDate": startDate,
      "endDate": endDate,
      "mode": "jobs"
    }
    jobRep.jobExtraction(payload).then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.data?.message)
        } else {
          sendNotify('success', response?.message)
          let obj = response?.data.map((data, i) => {
            return {
              id: data?.ticketData?.client_id,
              type: data?.ticketData?.client_name,
              createdby: data?.userData?.name,
              createdat: moment(data?.ticketData?.createdAt).format('ll'),
              nextdate: data?.recentChange ? data?.recentChange?.name : "--",
              assign: data?.assigned ? data?.assigned : "--",
              status: [data?.ticketData?.status],
              actions: (
                <div className="d-flex" >
                  <Link to={`/app/job/${data.ticketData.client_id}`} className='action-btn'><i className="far fa-eye"></i></Link>
                  <div className='action-btn' style={{ color: "red" }} onClick={() => showModal(data)}><i className="far fa-trash"></i></div>
                </div>
              ),
            }
          })
          let objs = response?.data.map((data, i) => {
            return {
              Client_Id: data?.ticketData?.client_id,
              Client_Name: data?.ticketData?.client_name,
              Created_By: data?.userData?.name,
              Created_At: moment(data?.ticketData?.createdAt).format('ll'),
              Recently_Changed_By: "Admin",
              Assigned_To: data?.assigned ? data?.assigned : "--",
              Status: data?.ticketData?.status,
            }
          })
          exportToExcel(objs, 'jobs.xlsx');
          setTableData(obj)
        }
      }
    })
  }
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };
  function exportToExcel(data, fileName) {
    const workbook = XLSX.utils.book_new();

    // Create a worksheet and add data to it
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Convert the workbook to a data URL
    const excelDataUrl = XLSX.write(workbook, { bookType: 'xlsx', type: 'base64' });

    // Create a data URI for the Excel file
    const dataUri = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${excelDataUrl}`;

    // Create an anchor element for downloading
    const a = document.createElement('a');
    a.href = dataUri;
    a.download = fileName;

    // Trigger a click event to initiate the download
    a.click();
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const idArray = selectedRows.map(row => row.tableId);
      setClientArrays(idArray)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };
  const cancelHandle = () => {
    setTicketModal(false)
  }
  let data = localStorage.getItem('session')
  let session = JSON.parse(data)
  let assignedBy = session.id

  const submitForm = () => {
    let payload = {
      "id": clientArray,
      "mode": "update",
      "status": "Assign",
      "assignedBy": assignedBy,
      "recentChange": assignedBy,
      "assinedTo": fromValues.assinedTo,
      "risk_level": fromValues.priority
    }
    setIsLoading(true)
    jobRep.bulkAcknowledgeTicket(payload).then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.data?.message)
        } else {
          sendNotify('success', response?.message)
          getjobs();
          setIsLoading(false)
          cancelHandle();
        }
      }
    })
  }

  const getAnalyst = () => {
    authRep.analyst().then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.message)
        } else {
          setAnalystList(response?.data)
        }
      }
    })
      .catch(error => (sendNotify('error', JSON.stringify(error))));
  }
  return (
    <section className='mt-md-3'>
      <div className="page-header mb-3">
        <h1 className="align-self-center">Jobs List</h1>
        <div className="page-header flex-md-row flex-column">
          {/* <div className="custom-search my-1">
            <input type="text" placeholder='Search by Job name' />
            <button><i className="far fa-search"></i></button>
          </div> */}
          <div className='d-flex'>
            <RangePicker onCalendarChange={getjobs} onChange={handleDateRangeChange} className='custom-datepicker' />
            <button className='primary-btn ms-2' onClick={() => dataExtraction()}><i className="far fa-file-export me-0 me-md-1"></i> <span className='d-none d-md-inline'>Extract</span></button>
          </div>
        </div>
      </div>
      <button className='primary-btn mb-4' onClick={() => setTicketModal(true)}><i class="fas fa-file-check"></i>Bulk Assign</button>

      <Table rowSelection={{
        type: "checkbox",
        ...rowSelection,
      }} columns={columns}
        loading={isLoading} scroll={{ x: '800' }}
        dataSource={tableData} className='custom-table' 
        
       // loading={false}
         />
      <Modal title="Confirmation Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Are you sure</p>
      </Modal>
      <Modal title="Bulk Assign" centered width={600} className='custom-modal' open={ticketModal} onOk={submitForm}
        onCancel={cancelHandle} okText={'Assign'}>
        <div className="job-cols mb-4">
          <label htmlFor="clientName" className="mb-3">Assigned To </label>
          <Select
            className='custom-select'
            style={{ width: 250 }}
            onChange={(value) => setFromValues({ ...fromValues, "assinedTo": value })}
            placeholder="Switch Job Status"
            options={analystList}
          />
        </div>
        <div className='job-cols mb-4'>
          <label className='mb-3'>Priority Level</label>
          <Select
            className='custom-select'
            style={{ width: 250 }}
            onChange={(value) => setFromValues({ ...fromValues, "priority": value })}
            placeholder="Switch Job Status"
            options={[
              {
                value: 'Low',
                label: 'Low',
              },
              {
                value: 'Medium',
                label: 'Medium',
              },
              {
                value: 'High',
                label: 'High',
              },
            ]}
          />
        </div>
        <div className='job-cols mb-4'>
          <label className='mb-3'>Comment</label>
          <textarea name="" id="" cols="50" rows="5"
            onChange={(value) => setFromValues({ ...fromValues, "assing": value })}
            className='custom-input'></textarea>
        </div>
      </Modal>
    </section>
  )
}
